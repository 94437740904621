import { VehicleType } from "@daytrip/legacy-enums";
import { MongoMultiPointLocation } from "@daytrip/legacy-models";
import { TransformToDate } from "@daytrip/legacy-transformers";
import { Expose } from "class-transformer";
import { Type } from "class-transformer";
import {
    IsArray,
    IsBoolean,
    IsDate,
    IsDefined,
    IsNumber,
    IsObject,
    IsOptional,
    IsString,
    IsUUID,
    IsEnum,
    ValidateNested,
} from "class-validator";
import { v4 as uuid } from "uuid";

import { GObjectType, GField, GInt } from "../graphql";
import { CDBaseEntity, CDColumn, CDEntity, CDObjectIdColumn } from "../orm";
import { resetTime } from "../utils/dates";

export const DEFAULT_BD_MESSAGE = "We have a limited number of drivers available for your selected date.";
export const DEFAULT_BD_CUTOFF_IN_HOURS = 36;
export const DEFAULT_BD_LOCATION_RADIUS_IN_METERS = 100000;

@CDEntity("blacklistedDepartureDates")
@GObjectType("BlacklistedDepartureDate")
export class BlacklistedDepartureDate extends CDBaseEntity {
    @CDObjectIdColumn()
    @IsUUID("4")
    @Expose()
    @IsDefined()
    public _id: string = uuid();

    @CDColumn()
    @IsDate()
    @IsDefined()
    @Expose()
    @TransformToDate
    @GField()
    public date: Date;

    @CDColumn()
    @IsString()
    @IsDefined()
    @Expose()
    @GField()
    public description: string;

    @CDColumn()
    @IsString()
    @IsDefined()
    @Expose()
    @GField()
    public message: string;

    @CDColumn()
    @IsArray()
    @Expose()
    @GField(() => [String])
    public countryIds: string[] = [];

    @CDColumn()
    @IsArray()
    @Expose()
    @GField(() => [String])
    public locationIds: string[] = [];

    @CDColumn()
    @IsArray()
    @Expose()
    @GField(() => [String])
    public routeIds: string[] = [];

    @CDColumn()
    @IsObject()
    @Expose()
    @IsOptional()
    public geolocations?: MongoMultiPointLocation;

    @CDColumn()
    @IsNumber()
    @Expose()
    @GField()
    @IsDefined()
    public locationRadiusMeters: number = 100000;

    /**
     * When isBlacklistedDate is true but there is also priceAdjustmentCoefficient/feeAdjustmentCoefficient
     * then user will get a warning and he can proceed with booking for the increased price on the website.
     * The booking will be created as Accepted. Via Partner API the route will not be available in this case.
     * When isBlacklistedDate is false and there is priceAdjustmentCoefficient/feeAdjustmentCoefficient
     * then the price will be increased without alerting the user. The booking will be created as Confirmed.
     * Via Partner API the route will be available at an increased price.
     */
    @CDColumn()
    @IsBoolean()
    @Expose()
    @IsDefined()
    @GField()
    public isBlacklistedDate: boolean = false;

    @CDColumn()
    @IsArray()
    @Expose()
    @GField(() => [VehicleTypeCoefficient])
    @IsDefined()
    @ValidateNested({ each: true })
    @Type(() => VehicleTypeCoefficient)
    public vehicleCoefficients: VehicleTypeCoefficient[] = [];

    @CDColumn()
    @IsDate()
    @IsDefined()
    @Expose()
    @TransformToDate
    @GField()
    public createdAt: Date;

    @CDColumn()
    @IsDate()
    @IsDefined()
    @Expose()
    @TransformToDate
    @GField()
    public updatedAt: Date;

    @CDColumn()
    @IsArray()
    @Expose()
    @GField(() => [LastMinuteCoefficient])
    @IsDefined()
    @ValidateNested({ each: true })
    @Type(() => LastMinuteCoefficient)
    public lastMinuteCoefficients: LastMinuteCoefficient[] = [];

    @CDColumn()
    @IsArray()
    @Expose()
    @GField(() => [TimeRangeCoefficient])
    @IsDefined()
    @ValidateNested({ each: true })
    @Type(() => TimeRangeCoefficient)
    public timeRangeCoefficients: TimeRangeCoefficient[] = [];

    @CDColumn()
    @IsArray()
    @Expose()
    @GField(() => [DistanceRangeCoefficient])
    @IsDefined()
    @ValidateNested({ each: true })
    @Type(() => DistanceRangeCoefficient)
    public distanceRangeCoefficients: DistanceRangeCoefficient[] = [];

    @CDColumn()
    @IsArray()
    @Expose()
    @GField(() => [ApiPartnerCoefficient])
    @IsDefined()
    @ValidateNested({ each: true })
    @Type(() => ApiPartnerCoefficient)
    public apiPartnerCoefficients: ApiPartnerCoefficient[] = [];

    public static get messagePlaceholder() {
        return DEFAULT_BD_MESSAGE;
    }

    public beforeSave() {
        this._id = this._id || uuid();
        this.message = this.message || DEFAULT_BD_MESSAGE;
        this.date = resetTime(this.date);
    }
}

@GObjectType("VehicleTypeCoefficient")
export class VehicleTypeCoefficient {
    @CDColumn()
    @IsEnum(VehicleType)
    @Expose()
    @GField(() => GInt)
    @IsDefined()
    public vehicleType: VehicleType;

    @CDColumn()
    @IsNumber()
    @Expose()
    @GField()
    @IsDefined()
    public priceAdjustmentCoefficient: number;

    @CDColumn()
    @IsNumber()
    @Expose()
    @GField()
    @IsDefined()
    public feeAdjustmentCoefficient: number;

    @CDColumn()
    @IsNumber()
    @Expose()
    @GField()
    @IsDefined()
    public unifiedAdjustmentCoefficient: number = 1; // default value so FE does not need to calculate this

    @CDColumn()
    @IsNumber()
    @Expose()
    @GField()
    @IsDefined()
    public cutoffInHours: number = DEFAULT_BD_CUTOFF_IN_HOURS;
}

@GObjectType("LastMinuteCoefficient")
export class LastMinuteCoefficient {
    @CDColumn()
    @IsNumber()
    @Expose()
    @GField()
    @IsDefined()
    public hoursFrom: number;

    @CDColumn()
    @IsNumber()
    @Expose()
    @GField()
    @IsDefined()
    public hoursTo: number;

    @CDColumn()
    @IsArray()
    @Expose()
    @GField(() => [VehicleType])
    @IsDefined()
    public vehicleTypes: VehicleType[] = [];

    @CDColumn()
    @IsNumber()
    @Expose()
    @GField()
    @IsDefined()
    public priceCoefficient: number;

    @CDColumn()
    @IsNumber()
    @Expose()
    @GField()
    @IsDefined()
    public feeCoefficient: number;

    @CDColumn()
    @IsNumber()
    @Expose()
    @GField()
    @IsDefined()
    public unifiedCoefficient: number = 1;

    @CDColumn()
    @IsBoolean()
    @Expose()
    @GField()
    @IsDefined()
    public isBlacklisted: boolean = false;
}

@GObjectType("TimeRangeCoefficient")
export class TimeRangeCoefficient {
    @CDColumn()
    @IsNumber()
    @Expose()
    @GField()
    @IsDefined()
    public hourFrom: number;

    @CDColumn()
    @IsNumber()
    @Expose()
    @GField()
    @IsDefined()
    public hourTo: number;

    @CDColumn()
    @IsNumber()
    @Expose()
    @GField()
    @IsDefined()
    public priceCoefficient: number;

    @CDColumn()
    @IsNumber()
    @Expose()
    @GField()
    @IsDefined()
    public feeCoefficient: number;

    @CDColumn()
    @IsNumber()
    @Expose()
    @GField()
    @IsDefined()
    public unifiedCoefficient: number = 1;

    @CDColumn()
    @IsBoolean()
    @Expose()
    @GField()
    @IsDefined()
    public isBlacklisted: boolean = false;
}

@GObjectType("DistanceRangeCoefficient")
export class DistanceRangeCoefficient {
    @CDColumn()
    @IsNumber()
    @Expose()
    @GField()
    @IsDefined()
    public distanceFromKm: number;

    @CDColumn()
    @IsNumber()
    @Expose()
    @GField()
    @IsDefined()
    public distanceToKm: number;

    @CDColumn()
    @IsNumber()
    @Expose()
    @GField()
    @IsDefined()
    public priceCoefficient: number;

    @CDColumn()
    @IsNumber()
    @Expose()
    @GField()
    @IsDefined()
    public feeCoefficient: number;

    @CDColumn()
    @IsNumber()
    @Expose()
    @GField()
    @IsDefined()
    public unifiedCoefficient: number = 1;

    @CDColumn()
    @IsBoolean()
    @Expose()
    @GField()
    @IsDefined()
    public isBlacklisted: boolean = false;
}

@GObjectType("ApiPartnerCoefficient")
export class ApiPartnerCoefficient {
    @CDColumn()
    @IsArray()
    @Expose()
    @GField(() => [String])
    @IsDefined()
    public apiPartnerIds: string[] = [];

    @CDColumn()
    @IsArray()
    @Expose()
    @GField(() => [VehicleType])
    @IsDefined()
    public vehicleTypes: VehicleType[] = [];

    @CDColumn()
    @IsNumber()
    @Expose()
    @GField()
    @IsDefined()
    public priceCoefficient: number;

    @CDColumn()
    @IsNumber()
    @Expose()
    @GField()
    @IsDefined()
    public feeCoefficient: number;

    @CDColumn()
    @IsNumber()
    @Expose()
    @GField()
    @IsDefined()
    public unifiedCoefficient: number = 1;

    @CDColumn()
    @IsBoolean()
    @Expose()
    @GField()
    @IsDefined()
    public isBlacklisted: boolean = false;
}

export interface BlacklistedDepartureDateRange {
    _id: string;
    date: Date;
    dateTo: Date;
    description: string;
    message: string;
    isBlacklistedDate: boolean;
    vehicleCoefficients: VehicleTypeCoefficient[];
    countryIds: string[];
    locationIds: string[];
    routeIds: string[];
    locationRadiusMeters: number;
    createdAt: Date;
    lastMinuteCoefficients: LastMinuteCoefficient[];
    timeRangeCoefficients: TimeRangeCoefficient[];
    distanceRangeCoefficients: DistanceRangeCoefficient[];
    apiPartnerCoefficients: ApiPartnerCoefficient[];
}

export interface PartialBlacklistedDepartureDateRange {
    date: Date;
    dateTo?: Date;
    countryIds?: string[];
    locationIds?: string[];
    routeIds?: string[];
    locationRadiusMeters?: number;
    description: string;
    message?: string;
    isBlacklistedDate?: boolean;
    vehicleCoefficients?: VehicleTypeCoefficient[];
    lastMinuteCoefficients?: LastMinuteCoefficient[];
    timeRangeCoefficients?: TimeRangeCoefficient[];
    distanceRangeCoefficients?: DistanceRangeCoefficient[];
    apiPartnerCoefficients?: ApiPartnerCoefficient[];
}

export type BlacklistedDepartureDateRangeSave = Omit<BlacklistedDepartureDateRange, "_id">;
