/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-alert */
/* eslint-disable no-return-assign */
import { DEFAULT_DESTINATION_RADIUS } from "@daytrip/constants";
import type {
    OrderChangesEmailArgs,
    OrderConfirmationEmailArgs,
    OrderOwnChildSeatEmailArgs,
    OrderPickupAddressMeetingPositionEmailArgs,
    OrdersReminderEmailArgs,
    PoolOrderConfirmedEmailArgs,
    TripTrackingEmailArgs,
} from "@daytrip/emails";
import { CHANGES_CANNOT_BE_GUARANTEED_IN_HOURS, DEFAULT_FEE_COEFFICIENT } from "@daytrip/legacy-config";
import { AirportTransferDirection } from "@daytrip/legacy-enums";
import type { LocationArrival, Ride } from "@daytrip/legacy-models";
import type { MeetingPosition } from "@daytrip/legacy-models";
import { OrderCancellationInfo } from "@daytrip/legacy-models";
import { Location, VEHICLE_CHANGED_CANCELLATION_INFO, VEHICLE_REMOVED_CANCELLATION_INFO } from "@daytrip/legacy-models";
import { Order, OrderPriceCalculationValidationOptions } from "@daytrip/legacy-models";
import { OrderContentLocation } from "@daytrip/legacy-models";
import { OrderCustomLocation } from "@daytrip/legacy-models";
import { Passenger } from "@daytrip/legacy-models";
import { VehicleTypePriceFee } from "@daytrip/legacy-models";
import { transformAssignationsToVehicleTypeAssignationsPairs } from "@daytrip/legacy-models";
import { transformOrderIdToBookingReference } from "@daytrip/legacy-transformers";
import { offsetBookingToLocalMin } from "@daytrip/legacy-transformers";
import { transformCurrencyToCurrencyISO } from "@daytrip/legacy-transformers";
import { transformNameToMachineName } from "@daytrip/legacy-transformers";
import { transformVehicleTypeToOfficialString } from "@daytrip/legacy-transformers";
import { transformBookingToUTC } from "@daytrip/utils";
import { AssignationStatus } from "@legacy/domain/AssignationStatus";
import { ChargebackStatus } from "@legacy/domain/ChargebackStatus";
import { Currency } from "@legacy/domain/Currency";
import { DiscountType } from "@legacy/domain/DiscountType";
import { EmailTypes } from "@legacy/domain/EmailTypes";
import { OrderStatus } from "@legacy/domain/OrderStatus";
import { OrderType } from "@legacy/domain/OrderType";
import { PassengerType } from "@legacy/domain/PassengerType";
import { PaymentStatus } from "@legacy/domain/PaymentStatus";
import { PaymentType } from "@legacy/domain/PaymentType";
import { PriceCalculator } from "@legacy/domain/PriceCalculator";
import type { SimpleCountry } from "@legacy/domain/SimpleCountry";
import type { SimpleCustomer } from "@legacy/domain/SimpleCustomer";
import type { SimpleDriver } from "@legacy/domain/SimpleDriver";
import { SimpleLocation } from "@legacy/domain/SimpleLocation";
import { SimpleTravelData } from "@legacy/domain/SimpleTravelData";
import { SimpleUser } from "@legacy/domain/SimpleUser";
import { VehicleInfo } from "@legacy/domain/VehicleInfo";
import { VehicleType, VehicleTypes } from "@legacy/domain/VehicleType";
import { filterSuitableVehicleInfo } from "@legacy/filters/filterSuitableVehicle";
import { Assignation } from "@legacy/models/Assignation";
import { Chargeback } from "@legacy/models/Chargeback";
import type { Compensation } from "@legacy/models/Compensation";
import { Discount } from "@legacy/models/Discount";
import { OrderChange } from "@legacy/models/OrderChange";
import { OrderChangesLog } from "@legacy/models/OrderChangesLog";
import type { Partner } from "@legacy/models/Partner";
import { Payment } from "@legacy/models/Payment";
import { PaymentRequest } from "@legacy/models/PaymentRequest";
import type { Penalty } from "@legacy/models/Penalty";
import type { Region } from "@legacy/models/Region";
import { Route } from "@legacy/models/Route";
import { RouteLocation } from "@legacy/models/RouteLocation";
import type { Subsidy } from "@legacy/models/Subsidy";
import { User } from "@legacy/models/User";
import type { Waypoint } from "@legacy/models/Waypoint";
import { RetrieveRoutesOptions } from "@legacy/options/RetrieveRoutesOptions";
import type { RetrieveUsersOptions } from "@legacy/options/RetrieveUsersOptions";
import { validateWithoutLogging } from "@legacy/services/ValidationService";
import { isUndefinedOrNull } from "@legacy/utils";
import { compareEditedOrderWithFetchedOrder } from "@legacy/utils/compareOrderObjects";
import { getRequiredCapacityForVehicle } from "@legacy/utils/getRequiredCapacityForVehicle";
import { getDefaultFeeCoefficient, getVehicleTypeFeeCoefficient } from "@legacy/utils/getVehicleTypeFeeCoefficient";
import autobind from "autobind-decorator";
import { classToPlain, plainToClass } from "class-transformer";
import { ValidationError } from "class-validator";
import { differenceInHours } from "date-fns";
import difference from "lodash/difference";
import sortBy from "lodash/sortBy";
import uniq from "lodash/uniq";
import { action, comparer, computed, observable, ObservableMap, reaction, runInAction, toJS } from "mobx";
import { Option } from "react-select-legacy";
import * as uuid from "uuid";

import { ApiPartnerModel } from "../../../api/src/domain/api-partner/models/api-partner.model";
import type { RoutingStore } from "../container";
import { globalManagementLogger } from "../global-logger";
import { calculateCapacityOfVehicles, isEnoughSpaceForLuggage } from "../utils";
import { calculateOptimalVehiclesForPassengers } from "../utils/calculateOptimalVehiclesForPassengers";
import { FetchDataStatus } from "../utils/FetchDataStatus";
import { getPendingLogsFromOrderLog } from "../utils/orderChangeLogUtils";

import { AssignationOperator } from "./AssignationOperator";
import { ChargebackOperator } from "./ChargebackOperator";
import type { CustomerFeedbackOperator } from "./CustomerFeedbackOperator";
import { DiscountOperator } from "./DiscountOperator";
import { ModelOperator } from "./ModelOperator";
import type { ModelOperatorOptions } from "./ModelOperatorOptions";
import type { OrderContentLocationOperatorOptions } from "./OrderContentLocationOperator";
import { ContentLocationOperator } from "./OrderContentLocationOperator";
import { CustomLocationOperator } from "./OrderCustomLocationOperator";
import { OrderVehicleOperator } from "./OrderVehicleOperator";
import { PassengerOperator } from "./PassengerOperator";
import { PaymentOperator } from "./PaymentOperator";
import { PaymentRequestOperator } from "./PaymentRequestOperator";

const PRICE_FEE_CALCULATION_DEBOUNCE = 300;

interface OrderOperatorOptions extends ModelOperatorOptions<Order, OrderOperatorModules, OrderOperatorData> {
    vehicleOperators?: Array<OrderVehicleOperator>;
}

interface OrderOperatorModules {
    routingStore: RoutingStore;
}

interface OrderOperatorData {
    simpleCountries?: Array<SimpleCountry>;
    regions?: Array<Region>;
    simpleLocations?: Array<SimpleLocation>; // used fot locations select
    simpleUsers?: Array<SimpleUser | SimpleCustomer>;
    simpleDrivers?: Array<SimpleDriver>;
    locations: Array<Location>; // only locations necessary to calculate price etc.
    discounts: Array<Discount>;
    subsidies: Array<Subsidy>;
    compensations: Array<Compensation>;
    penalties: Array<Penalty>;
    assignations: Array<Assignation>;
    meetingPosition?: MeetingPosition;
    ride?: Ride;
    route?: Route;
    userFromOrder?: User;
}

interface OrderOperatorDataFetched {
    simpleCountries: Array<SimpleCountry>;
    regions: Array<Region>;
    simpleLocations: Array<SimpleLocation>;
    simpleUsers: Array<SimpleUser | SimpleCustomer>;
    simpleDrivers: Array<SimpleDriver>;
    discounts: Array<Discount>;
    subsidies: Array<Subsidy>;
    compensations: Array<Compensation>;
    penalties: Array<Penalty>;
    meetingPosition?: MeetingPosition;
    ride?: Ride;
    route: Route;
    userFromOrder?: User;
}

@autobind
export class OrderOperator extends ModelOperator<
    Order,
    OrderOperatorOptions,
    OrderOperatorModules,
    OrderOperatorData,
    OrderOperatorDataFetched
> {
    public constructor(options: OrderOperatorOptions) {
        super(options);
        this.vehicleOperators = options.vehicleOperators ?? [];
    }

    @observable
    public locationsTravelData: Array<SimpleTravelData> = [];

    @observable
    public baseRouteTravelData: SimpleTravelData;

    @observable
    public originalVehicleTypesPricesFees?: Array<VehicleTypePriceFee>;

    @observable
    public ccAlias?: string;

    @observable
    public ccAliasNotFound?: boolean;

    @observable
    public allSubsidies: boolean = false;

    @action
    public async ensureSubsidies() {
        if (!this.allSubsidies) {
            this.data.subsidies = await this.rpcClient.assignation.retrieveSubsidies({});

            this.vehicleOperators.forEach((vo) => {
                vo.data.subsidies = this.data.subsidies;

                if (vo.assignationOperator !== undefined) {
                    vo.assignationOperator.data.subsidies = this.data.subsidies;
                }
            });

            this.previousAssignationOperators.forEach((pao) => {
                pao.data.subsidies = this.data.subsidies;
            });

            this.allSubsidies = true;
        }
    }

    @observable
    public allCompensations: boolean = false;

    @action
    public async ensureCompensation() {
        if (!this.allCompensations) {
            this.data.compensations = await this.rpcClient.assignation.retrieveCompensations({});

            this.vehicleOperators.forEach((vo) => {
                vo.data.compensations = this.data.compensations;

                if (vo.assignationOperator !== undefined) {
                    vo.assignationOperator.data.compensations = this.data.compensations;
                }
            });

            this.previousAssignationOperators.forEach((pao) => {
                pao.data.compensations = this.data.compensations;
            });

            this.allCompensations = true;
        }
    }

    @observable
    public allPenalties: boolean = false;

    @action
    public async ensurePenalties() {
        if (!this.allPenalties) {
            this.data.penalties = await this.rpcClient.assignation.retrievePenalties({});

            this.vehicleOperators.forEach((vo) => {
                vo.data.penalties = this.data.penalties;

                if (vo.assignationOperator !== undefined) {
                    vo.assignationOperator.data.penalties = this.data.penalties;
                }
            });

            this.previousAssignationOperators.forEach((pao) => {
                pao.data.penalties = this.data.penalties;
            });

            this.allPenalties = true;
        }
    }

    @observable
    public fullyFetched: boolean = false;

    @action
    public setFullyFetched() {
        this.fullyFetched = true;
    }

    @observable
    public orderLastSaved?: Date;

    @observable
    public travelAgentId?: string;

    @observable
    public passengers: Array<PassengerOperator> = [];

    public userIdReaction = reaction(
        () => this.m.userId,
        async () => {
            if (
                !isUndefinedOrNull(this.user) &&
                !isUndefinedOrNull(this.passengers) &&
                this.passengers.length &&
                !isUndefinedOrNull(this.passengers[0] && isUndefinedOrNull(this.passengers[0].userId))
            ) {
                this.passengers[0].edit(async (p) => {
                    const user = plainToClass(User, await this.rpcClient.user.retrieveUser(this.m.userId, true));

                    const passengerBirthday = user.birthdayAt;

                    p.userId = user._id;
                    p.firstName = user.firstName;
                    p.lastName = user.lastName;
                    p.phoneNumber = user.phoneNumber;
                    p.email = user.email;
                    p.countryId = user.countryId;
                    p.birthdayAt = passengerBirthday;
                });

                this.travelAgentId = this.user.isTravelAgent ? this.user._id : undefined;
            }
        },
        { delay: 100 },
    );

    public pricingCountryIdReaction = reaction(
        () => this.m.pricingCountryId,
        async () => {
            if (!this.m.pricingCountryId || !this.route) {
                return;
            }

            return this.updatePricingCurrency();
        },
    );

    public get user(): undefined | SimpleUser {
        const user = this.data.simpleUsers?.find((u) => u._id === this.m.userId);

        if (user !== undefined) {
            return plainToClass(SimpleUser, user);
        }

        return undefined;
    }

    public partner?: Partner;

    @observable
    public createdByUser?: SimpleUser;

    @observable
    public affiliateCustomMarkup?: number;

    @observable
    public isOrderStatusChanging: boolean;

    @observable
    public newOrder: boolean = false;

    @action
    public async fetchOrder() {
        const order = plainToClass(Order, await this.rpcClient.order.retrieveOrder(this.m._id));
        this.model = order;

        if (this.isEdited()) {
            if (this.model.version - this.editedModel.version === 1) {
                const changedKeys = compareEditedOrderWithFetchedOrder(toJS(this.editedModel), order);

                const isNothingOrOnlyVersionUpdated =
                    changedKeys.length === 0 || (changedKeys.length === 1 && changedKeys[0] === "version");

                if (isNothingOrOnlyVersionUpdated) {
                    this.edit((o) => (o.version += 1));
                } else {
                    const updateAlsoEditedOrder = confirm(
                        [
                            "There is newer version of the booking. These fields may differ:",
                            changedKeys.filter((key) => key !== "version").join(", "),
                            "\nDo you want to rewrite the latest booking data with your unsaved changes?",
                        ].join("\n"),
                    );

                    if (updateAlsoEditedOrder) {
                        // prevent overwritting automatic emails
                        this.edit((o) => (o.automaticEmails = order.automaticEmails));
                        this.edit((o) => (o.version += 1));
                    } else {
                        this.cancelEdit();
                        await this.fetchData();
                        this.edit(() => {});
                    }
                }
            }
        }
    }

    @action
    private async handleOrderStatusChange(
        statusChange: () => Promise<void>,
        options?: { fetchOrder?: boolean; reloadPayments?: boolean },
    ) {
        this.isOrderStatusChanging = true;

        await statusChange();

        if (options?.fetchOrder) {
            await this.fetchOrder();
        }
        if (options?.reloadPayments) {
            await this.reloadPayments();
        }

        this.isOrderStatusChanging = false;
    }

    @action
    public async recreateOrder(): Promise<void> {
        const reacreateOrder = async () => {
            const oldOrder = this.model;
            let newOrder = new Order();

            const {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                _id,
                createdAt,
                acceptedAt,
                declinedAt,
                confirmedAt,
                cancelledAt,
                automaticEmails,
                paymentMethod,
                draftedAt,
                createdBy,
                claimRequests,
                version,
                travelEstimates,
                ...orderDataToKeep
            } = oldOrder;

            newOrder = Object.assign(newOrder, orderDataToKeep);

            const newOrderId = await this.rpcClient.order.createOrder(newOrder);

            this.newOrder = false;
            newOrder._id = newOrderId;
            newOrder.discountIds = [];
            newOrder.automaticEmails = [];
            this.model = newOrder;

            this.modules.routingStore.push({ pathname: "order", search: `?orderId=${newOrderId}` });

            this.vehicleOperators = this.vehicleOperators.map((vo) => {
                vo.orderId = newOrder._id;
                vo.assignationOperator = undefined;
                return vo;
            });

            this.paymentRequestOperators = [];
            this.paymentOperators = [];
        };

        this.handleOrderStatusChange(reacreateOrder);
    }

    @computed
    public get isPickupAddressApprovalAllowed(): boolean {
        if (this.authenticationStore.isSupportMaster) {
            return true;
        }

        // prevent an edge case, when Draft orders are manually changed by CS
        if (this.m.status === OrderStatus.Draft) {
            return false;
        }

        return this.authenticationStore.hasPermissions("Order:Update");
    }

    @computed
    public get isDropoffAddressApprovalAllowed(): boolean {
        const { isSupportMaster } = this.authenticationStore;
        const isAllowedToUpdateOrder = this.authenticationStore.hasPermissions("Order:Update");
        return isSupportMaster || (isAllowedToUpdateOrder && this.m.status !== OrderStatus.Draft);
    }

    @action
    public async copyToClipboard(): Promise<boolean> {
        if (!this.originLocation || !this.destinationLocation) {
            alert("Origin or destination is missing");
            return false;
        }
        // cancel out local timezone
        const timezoneDiff = this.m.departureAt.getTimezoneOffset() * 60000;
        const adjustedDate = new Date(this.m.departureAt.valueOf() + timezoneDiff);
        const departureDateString = adjustedDate.toLocaleDateString("en-US", {
            day: "numeric",
            month: "long",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
        });
        const contentLocations = this.m.contentLocations
            .map((contentLocation) => {
                const location = this.data.locations.find((loc) => loc._id === contentLocation.locationId);
                if (location) {
                    return ` via ${location.name} (${contentLocation.duration}min)`;
                }
            })
            .filter((contentLocation) => contentLocation);
        const customLocations = this.m.customLocations.map(
            (location) => ` via ${location.title} (${location.duration}min)`,
        );
        const allStops = [...contentLocations, ...customLocations];
        const allStopsString = allStops.length ? `${allStops.join("")},` : "";
        const vehicleTypeStrings = this.m.vehicles
            .map((vehicle) => transformVehicleTypeToOfficialString(vehicle))
            .join(" + ");
        const orderString = `${transformOrderIdToBookingReference(this.m._id)} ${departureDateString} ${
            this.originLocation?.name
        } to ${this.destinationLocation?.name},${allStopsString} ${
            this.passengers.length
        }pax ${vehicleTypeStrings}, ${this.m.totalPrice} ${transformCurrencyToCurrencyISO(this.m.pricingCurrency)}`;
        navigator.clipboard.writeText(orderString);
        return true;
    }

    @action
    public async convertAirportTransferToRegularOrder(): Promise<void> {
        if (this.m.airportTransferDirection === AirportTransferDirection.None) {
            return;
        }

        const isInEditMode = this.isEdited();

        this.edit((o) => {
            o.airportTransferDirection = AirportTransferDirection.None;
        });

        if (!isInEditMode) {
            await this.save();
        }
    }

    @action
    public async setOrderPending(): Promise<void> {
        const setOrderPending = async () => {
            try {
                await this.rpcClient.order.setOrderPending(this.m._id);
                this.m.draftedAt = undefined;
            } catch (e: any) {
                alert("Can't set order as pending.");
            }
        };

        this.handleOrderStatusChange(setOrderPending, { fetchOrder: true });
    }

    @action
    public async acceptOrder(sendEmail: boolean = false): Promise<void> {
        const acceptOrder = async () => {
            try {
                await this.rpcClient.order.acceptOrder(this.m._id, sendEmail);
                this.m.acceptedAt = new Date();
            } catch (e: any) {
                alert("Can't accept order");
            }
        };

        this.handleOrderStatusChange(acceptOrder, { fetchOrder: true });
    }

    @observable
    public dynamicPriceDifference: number = 0;

    @action
    public async calculateOrderDynamicPriceDifference(): Promise<void> {
        if (!this.m.originLocationId || !this.m.destinationLocationId || !this.m.departureAt) {
            return;
        }

        const priceCoefficients = await this.rpcClient.order.getOrderPriceAdjustmentCoefficient(
            this.m.originLocationId,
            this.m.destinationLocationId,
            this.m.departureAt,
            this.m.vehicles,
            this.m.pricingCountryId,
            this.m.routeId,
            this.m.dynamicPricing?.distanceKm,
            this.m.apiPartnerId,
            this.m.dynamicPricing?.vehiclesWithOnlyOriginPricing,
            this.m.dynamicPricing?.vehiclesWithOnlyDestinationPricing,
            this.m.pickupPosition,
            this.m.dropoffPosition,
        );

        const order = { ...this.m } as Order;
        order.priceAdjustmentCoefficient = priceCoefficients.priceAdjustmentCoefficient;
        order.feeAdjustmentCoefficient = priceCoefficients.feeAdjustmentCoefficient;
        order.vehicleCoefficients = priceCoefficients.vehicleCoefficients;

        // calculate locations with coefficient if applicable
        order.contentLocations = order.contentLocations.map((location) => {
            // in case the waiting price was set to zero, we do not want to calculate it
            if (location.waitingPrice === 0 && location.waitingFee === 0) {
                return location;
            }

            const pricingRegionOrCountry = this.pricingRegion ?? this.pricingCountry;
            const countryGuideLocationPrice = pricingRegionOrCountry?.guideLocationPrice || 0;
            const waitingPrice = PriceCalculator.calculateWaitingPriceFromDuration(
                location.duration,
                countryGuideLocationPrice,
            );
            const feeCoefficient = pricingRegionOrCountry
                ? getDefaultFeeCoefficient(pricingRegionOrCountry)
                : DEFAULT_FEE_COEFFICIENT;
            const waitingFee = PriceCalculator.calculateFeeFromPrice(waitingPrice, feeCoefficient);

            return {
                ...location,
                waitingPrice: PriceCalculator.multiplyByCoefficient(
                    waitingPrice,
                    priceCoefficients.priceAdjustmentCoefficient,
                ),
                waitingFee: PriceCalculator.multiplyByCoefficient(
                    waitingFee,
                    priceCoefficients.feeAdjustmentCoefficient,
                ),
            };
        });

        this.updateUiContentLocations(order);

        await validateWithoutLogging(Order, order, OrderPriceCalculationValidationOptions);

        const price = PriceCalculator.calculateOrderPrice(order, this.orderDiscounts, true);
        const fee = PriceCalculator.calculateOrderFee(order, this.orderDiscounts, true);
        const newTotalPrice = price + fee;
        this.dynamicPriceDifference = newTotalPrice - (this.m.totalPrice || 0);
    }

    public updateUiContentLocations(order: Order) {
        // update the UI component with dynamic coefficient if applicable
        this.contentLocationOperators = this.contentLocationOperators.map((operator) => {
            const contentLocation = order.contentLocations.find(
                (location) => location.locationId === operator.data.location._id,
            );
            const currentContentLocationOperator = operator;
            if (contentLocation) {
                currentContentLocationOperator.m.waitingPrice = contentLocation.waitingPrice;
                currentContentLocationOperator.m.waitingFee = contentLocation.waitingFee;
            }
            return currentContentLocationOperator;
        });
    }

    @action
    public async cancelOrder(cancellationInfo: OrderCancellationInfo, sendEmails: boolean = false): Promise<void> {
        const cancelOrder = async () => {
            try {
                await this.rpcClient.order.cancelOrder(this.m._id, { cancellationInfo, sendEmails });
                this.m.cancelledAt = new Date();
            } catch (e: any) {
                alert("Can't cancel order");
            }
        };

        await this.handleOrderStatusChange(cancelOrder, { fetchOrder: true, reloadPayments: true });
    }

    @action
    public async declineOrder(sendEmail: boolean = false): Promise<void> {
        const declineOrder = async () => {
            try {
                await this.rpcClient.order.declineOrder(this.m._id, sendEmail);
                this.m.declinedAt = new Date();
            } catch (e: any) {
                alert("Can't decline order");
            }
        };

        this.handleOrderStatusChange(declineOrder, { fetchOrder: true, reloadPayments: true });
    }

    @action
    public async confirmOrder(sendEmail: boolean = false): Promise<void> {
        if (!this.isLeadPassengerInfoFilled()) {
            if (this.m.type === OrderType.Shared) {
                alert("Please fill in the lead passenger's information before confirming the order.");
                return;
            }
            confirm("Are you sure you want to confirm the order without filling in the lead passenger's information?");
        }

        if (this.m.type === OrderType.Shared && !this.isPickupAndDropOffAddressFilled()) {
            alert("Cannot confirm order without pickup and dropoff address.");
            return;
        }

        const confirmOrder = async () => {
            try {
                await this.rpcClient.orderManagement.confirmOrderForManagement(this.m._id, sendEmail);
                this.m.confirmedAt = new Date();
            } catch (error: any) {
                alert(`Can't confirm order${error ? `:\n\n${error}` : ""}`);
            }
        };

        this.handleOrderStatusChange(confirmOrder, {
            fetchOrder: true,
            reloadPayments: true,
        });
    }

    private isLeadPassengerInfoFilled(): boolean {
        if (this.passengers.length === 0) {
            return false;
        }

        const leadPassenger = this.passengers.find((passenger) => passenger.m.type === PassengerType.Lead);

        if (!leadPassenger) {
            return false;
        }

        return (
            Boolean(leadPassenger.m.firstName) &&
            Boolean(leadPassenger.m.lastName) &&
            Boolean(leadPassenger.m.email) &&
            Boolean(leadPassenger.m.phoneNumber)
        );
    }

    private isPickupAndDropOffAddressFilled(): boolean {
        return Boolean(this.m.pickupAddress) && Boolean(this.m.dropoffAddress);
    }

    @observable
    public sendingEmailType?: EmailTypes = undefined;

    @observable
    public sentEmailType?: EmailTypes = undefined;

    @computed
    public get isSendingOrderReminder(): boolean {
        return this.sendingEmailType === EmailTypes.ordersReminder;
    }

    @computed
    public get isOrderReminderSent(): boolean {
        return this.sentEmailType === EmailTypes.ordersReminder;
    }

    @computed
    public get isSendingOrderConfirmation(): boolean {
        return (
            this.sendingEmailType === EmailTypes.orderConfirmation ||
            this.sendingEmailType === EmailTypes.poolOrderConfirmed
        );
    }

    @computed
    public get isOrderConfirmationSent(): boolean {
        return (
            this.sentEmailType === EmailTypes.orderConfirmation || this.sentEmailType === EmailTypes.poolOrderConfirmed
        );
    }

    @computed
    public get isSendingMeetingPostionEmail(): boolean {
        return this.sendingEmailType === EmailTypes.orderPickupAddressMeetingPosition;
    }

    @computed
    public get isMeetingPositionEmailSent(): boolean {
        return this.sentEmailType === EmailTypes.orderPickupAddressMeetingPosition;
    }

    @computed
    public get isTripTrackingEmailSent(): boolean {
        return this.sentEmailType === EmailTypes.tripTrackingEmail;
    }

    @computed
    public get isSendingTripTrackingEmail(): boolean {
        return this.sendingEmailType === EmailTypes.tripTrackingEmail;
    }

    @action
    private async handleEmailSending<TArgs extends Record<string, unknown>>(emailType: EmailTypes, options: TArgs) {
        this.sendingEmailType = emailType;
        await this.rpcClient.email.sendEmail<TArgs>(emailType, options);
        this.sendingEmailType = undefined;

        this.sentEmailType = emailType;

        setTimeout(() => {
            this.sentEmailType = undefined;
        }, 3500);
    }

    @action
    public async sendReminder(): Promise<void> {
        // send own child seat email with reminder
        await this.handleEmailSending<OrderOwnChildSeatEmailArgs>(EmailTypes.orderOwnChildSeat, {
            orderIds: [this.model._id],
        });
        await this.handleEmailSending<OrdersReminderEmailArgs>(EmailTypes.ordersReminder, {
            orderIds: [this.model._id],
        });
    }

    @action
    public async sendConfirmation(): Promise<void> {
        if (this.model.type === OrderType.Shared) {
            return this.handleEmailSending<PoolOrderConfirmedEmailArgs>(EmailTypes.poolOrderConfirmed, {
                order: this.model,
                origin: this.originLocation!,
                destination: this.destinationLocation!,
                meetingPosition: this.meetingPosition,
            });
        }

        return this.handleEmailSending<OrderConfirmationEmailArgs>(EmailTypes.orderConfirmation, {
            orderIds: [this.model._id],
            notifyManagement: false,
        });
    }

    @action
    public async sendMeetingPositionEmail(): Promise<void> {
        await this.handleEmailSending<OrderPickupAddressMeetingPositionEmailArgs>(
            EmailTypes.orderPickupAddressMeetingPosition,
            {
                orderIds: [this.model._id],
            },
        );
    }

    @action
    public async sendTripTrackingEmail(): Promise<void> {
        await this.handleEmailSending<TripTrackingEmailArgs>(EmailTypes.tripTrackingEmail, {
            orderIds: [this.model._id],
        });
    }

    @action
    public async createDestinationLocation(option: Option): Promise<string | undefined> {
        if (!isUndefinedOrNull(this.data.simpleCountries)) {
            const { position, countryId } = await this.rpcClient.maps.fetchPositionByAddress(option.value as string);

            let location = classToPlain(new Location()) as Location;

            location = Object.assign(location, {
                name: option.value as string,
                localNames: [],
                position,
                countryId,
                machineName: transformNameToMachineName(option.value as string),
                isDestination: true,
                radiusKm: DEFAULT_DESTINATION_RADIUS,
            });

            let locationId = "";

            try {
                locationId = await this.rpcClient.content.createLocation(location);
                location = await this.rpcClient.content.retrieveLocation(locationId);
            } catch (e: any) {
                alert(`Unable to create location: ${e}`);
                return Promise.resolve(undefined);
            }

            const country = await this.rpcClient.content.retrieveCountry(location.countryId);

            location._id = locationId;

            const simpleLocation = {
                locationId,
                name: location.name,
                machineName: location.machineName,
                localNames: location.localNames,
                countryId: location.countryId,
                countryName: country.englishName,
                position: location.position,
            } as SimpleLocation;

            if (this.data.simpleLocations === undefined) {
                this.data.simpleLocations = [];
            }

            this.data.simpleLocations.push(simpleLocation);

            if (this.data.locations === undefined) {
                this.data.locations = [];
            }

            this.data.locations.push(location);

            return Promise.resolve(locationId);
        }

        return Promise.resolve(undefined);
    }

    @observable
    public selectedLocation?: string;

    @action
    public orderContentSelectLocationToAdd(option: Option) {
        this.selectedLocation = option.value as string;
    }

    public getCountryForLocation(location?: SimpleLocation | Location): SimpleCountry | undefined {
        if (location) {
            return this.data.simpleCountries?.find((c) => c._id === location.countryId);
        }
        return undefined;
    }

    @action
    public async orderContentLocationAdd(locationId: string) {
        if (!locationId) {
            return;
        }

        if (this.pricingCountry) {
            const newLocations: Array<OrderContentLocation> = this.m.contentLocations;
            const newLocation = await this.rpcClient.content.retrieveLocation(locationId);

            // add dynamic pricing coefficient if applicable
            const priceCoefficients = await this.rpcClient.order.getOrderPriceAdjustmentCoefficient(
                this.m.originLocationId,
                this.m.destinationLocationId,
                this.m.departureAt,
                this.m.vehicles,
                this.m.pricingCountryId,
                this.m.routeId,
                this.m.dynamicPricing?.distanceKm,
                this.m.apiPartnerId,
                this.m.dynamicPricing?.vehiclesWithOnlyOriginPricing,
                this.m.dynamicPricing?.vehiclesWithOnlyDestinationPricing,
                this.m.pickupPosition,
                this.m.dropoffPosition,
            );

            const guideLocationPrice =
                this.pricingRegion?.guideLocationPrice || this.pricingCountry.guideLocationPrice || 0;
            const waitingPrice = PriceCalculator.multiplyByCoefficient(
                PriceCalculator.calculateWaitingPriceFromDuration(newLocation.defaultDuration, guideLocationPrice),
                priceCoefficients.priceAdjustmentCoefficient,
            );
            const feeCoefficient = getDefaultFeeCoefficient(this.pricingCountry);
            const waitingFee = PriceCalculator.multiplyByCoefficient(
                PriceCalculator.calculateFeeFromPrice(waitingPrice, feeCoefficient),
                priceCoefficients.feeAdjustmentCoefficient,
            );
            const defaultVehicleTypesPricesFees = VehicleTypes.map((vehicleType: VehicleType) =>
                Object.assign(new VehicleTypePriceFee(), {}, { vehicleType }),
            );

            let routeLocation = this.route?.locations.find((l) => l.locationId === newLocation._id);

            if (routeLocation === undefined) {
                routeLocation = new RouteLocation();
                routeLocation.locationId = newLocation._id;
                routeLocation.defaultDuration = newLocation.defaultDuration;
                routeLocation.additionalPrice = newLocation.additionalPrice;
                routeLocation.additionalFee = newLocation.additionalFee;

                const currentRoute = plainToClass(Route, this.route);
                if (this.route) {
                    currentRoute.locations = [...this.route.locations, routeLocation!];
                }

                // do not recalculate custom route
                if (this.route?._id !== "") {
                    let updatedRoute: Route | undefined;

                    try {
                        updatedRoute = await this.rpcClient.content.calculateRoutePrices(
                            plainToClass(Route, currentRoute),
                            false,
                        );
                    } catch (e: any) {
                        globalManagementLogger.error(e);
                        globalManagementLogger.info("Unable to calculate travelling data for this route.");
                    } finally {
                        if (updatedRoute) {
                            this.route = updatedRoute;
                            routeLocation.vehicleTypesPricesFees = updatedRoute.locations.find(
                                (rl) => rl.locationId === newLocation._id,
                            )!.vehicleTypesPricesFees;
                        } else {
                            routeLocation.vehicleTypesPricesFees = defaultVehicleTypesPricesFees;
                        }
                    }
                } else {
                    routeLocation.vehicleTypesPricesFees = defaultVehicleTypesPricesFees;
                }
            }

            const contentLocation = new OrderContentLocation();
            contentLocation.order = newLocations.length + this.m.customLocations.length + 1;
            contentLocation.locationId = newLocation._id;
            contentLocation.duration = newLocation.defaultDuration;
            contentLocation.waitingPrice = waitingPrice;
            contentLocation.waitingFee = waitingFee;
            contentLocation.vehicleTypesPricesFees = routeLocation.vehicleTypesPricesFees;

            newLocations.push(contentLocation);
            this.locationsTravelData = [];
            await this.calculateLocationsTravelData(newLocations, this.m.customLocations);

            const location = await this.rpcClient.content.retrieveLocation(contentLocation.locationId);

            if (this.data.locations === undefined) {
                this.data.locations = [];
            }

            this.data.locations.push(location);

            this.contentLocationOperators.push(
                new ContentLocationOperator({
                    modelConstructor: OrderContentLocation,
                    model: contentLocation,
                    data: {
                        countries: this.data.simpleCountries ?? [],
                        location,
                    },
                    modules: null,
                }),
            );

            this.selectedLocation = undefined;
            await this.setLocationArrivalTimes();
        }
    }

    public contentLocationsReaction = reaction(
        () =>
            JSON.stringify(
                this.contentLocationOperators?.map((contentLocationOperator) => toJS(contentLocationOperator.m)),
            ),
        async () => {
            if (this.isEdited()) {
                this.edit((o) => (o.contentLocations = this.contentLocationOperators.map((clo) => clo.m)));

                this.allLocations.map(async (location, order) => (location.m.order = order + 1));
            }
        },
    );

    @action
    public populateContentLocationOperators() {
        if (this.data.locations) {
            this.contentLocationOperators = this.m.contentLocations.map((cl) => {
                const options: OrderContentLocationOperatorOptions = {
                    modelConstructor: OrderContentLocation,
                    data: {
                        countries: this.data.simpleCountries!,
                        location: this.data.locations.find((l) => l._id === cl.locationId)!,
                    },
                    modules: null,
                    model: cl,
                };
                return new ContentLocationOperator(options);
            });
        }
    }

    @action
    public async setInitialLocationArrivals() {
        if (this.m.travelEstimates) {
            this.locationArrivalTimes = this.m.travelEstimates?.locationArrivals || [];
        } else {
            await this.setLocationArrivalTimes();
        }
    }

    @observable
    public customLocationName: string = "";

    @action
    public updateCustomLocationName(value: string) {
        this.customLocationName = value;
    }

    public customLocationsReaction = reaction(
        () =>
            JSON.stringify(
                this.customLocationOperators?.map((customLocationOperator) => toJS(customLocationOperator.m)),
            ),
        async () => {
            if (this.isEdited()) {
                this.edit((o) => (o.customLocations = this.customLocationOperators.map((clo) => clo.m)));

                this.allLocations.map(async (location, order) => (location.m.order = order + 1));
            }
        },
    );

    @action
    public populateCustomLocationOperators() {
        this.customLocationOperators = this.m.customLocations.map(
            (cl) =>
                new CustomLocationOperator({
                    modelConstructor: OrderCustomLocation,
                    data: {
                        countries: this.data.simpleCountries!,
                    },
                    modules: {},
                    model: cl,
                }),
        );
    }

    @action
    public async createCustomLocation(): Promise<void> {
        if (!this.customLocationName) {
            return;
        }

        const customLocation = new OrderCustomLocation();

        const positionWithCountry = await this.rpcClient.maps.fetchPositionByAddress(this.customLocationName);

        // order consists of content and custom locations
        const order = this.m.contentLocations.length + this.m.customLocations.length + 1;

        const defaultVehicleTypesPricesFees = VehicleTypes.map((vehicleType: VehicleType) =>
            Object.assign(new VehicleTypePriceFee(), {}, { vehicleType }),
        );

        customLocation.title = this.customLocationName;
        customLocation.position = positionWithCountry.position;
        customLocation.countryId = positionWithCountry.countryId;
        customLocation.duration = 0;
        customLocation.waitingPrice = 0;
        customLocation.waitingFee = 0;
        customLocation.order = order;
        customLocation.vehicleTypesPricesFees = defaultVehicleTypesPricesFees;

        try {
            const customLocationSimpleTravelData = await this.rpcClient.content.calculateCustomRouteLocationsTravelData(
                customLocation.position,
                this.m.originLocationId,
                this.m.destinationLocationId,
            );

            if (!this.baseRouteTravelData) {
                let waypoints: Array<Waypoint> = [];

                if (this.m.routeId && this.route) {
                    waypoints = this.route.waypoints;
                }

                this.baseRouteTravelData = await this.rpcClient.content.calculateBaseRouteTravelData(
                    this.m.originLocationId,
                    this.m.destinationLocationId,
                    waypoints,
                );
            }

            this.locationsTravelData.push(customLocationSimpleTravelData);

            this.customLocationOperators.push(
                new CustomLocationOperator({
                    modelConstructor: OrderCustomLocation,
                    model: customLocation,
                    modules: {},
                    data: {
                        countries: this.data.simpleCountries!,
                    },
                }),
            );

            this.customLocationName = "";
            await this.setLocationArrivalTimes();
        } catch (e: any) {
            globalManagementLogger.error(e);
            alert(
                "Custom location travel data calculation failed. Please provide custom location more specifically. Ex. Steyr, Austria",
            );
        }
    }

    @action
    public orderContentLocationRemove(locationId: string) {
        this.contentLocationOperators = this.contentLocationOperators.filter((cl) => cl.m.locationId !== locationId);

        this.locationsTravelData = this.locationsTravelData.filter(
            (td: SimpleTravelData) => td.locationId !== locationId,
        );
        this.setLocationArrivalTimes();
    }

    @action
    public orderCustomLocationRemove(locationTitle: string) {
        this.customLocationOperators = this.customLocationOperators.filter((cl) => cl.m.title !== locationTitle);

        // probably not the best way to get rid of not needed travel
        this.locationsTravelData = [];
        this.calculateLocationsTravelData(
            this.m.contentLocations,
            this.customLocationOperators.map((clo) => clo.m),
        );
        this.setLocationArrivalTimes();
    }

    @observable
    public contentLocationSearchOption?: Option;

    @action
    public async onContentLocationsFilterSelect(option: Option): Promise<void> {
        this.contentLocationSearchOption = option;
        this.orderContentSelectLocationToAdd(option);
    }

    @action
    public async onContentLocationsFilterInputChange(value?: string): Promise<void> {
        if (value && value.length > 2) {
            this.simpleLocations = plainToClass(
                SimpleLocation,
                await this.rpcClient.content.retrieveSimpleLocations({ searchString: value }),
            );

            const routeLocationIds = this.route?.locations.map((rl) => rl.locationId) ?? [];

            // We add these fields to be able to sort by them. Check second parameter of `sortBy` to
            // see sorting priority
            this.simpleLocations = sortBy(
                this.simpleLocations.map((l) =>
                    Object.assign(l, {
                        isRouteLocation: !routeLocationIds.includes(l.locationId),
                        inOriginCountry: !(
                            this.originLocationCountry && l.countryId === this.originLocationCountry._id
                        ),
                        inDestinationCountry: !(
                            this.destinationLocationCountry && l.countryId === this.destinationLocationCountry._id
                        ),
                    }),
                ),
                ["isRouteLocation", "inOriginCountry", "inDestinationCountry", "name"],
            );

            if (this.data.simpleLocations === undefined) {
                this.data.simpleLocations = [];
            }

            this.simpleLocations.forEach((sl) => this.data.simpleLocations!.push(sl));
        }
    }

    // vehicles and drivers
    @observable
    public vehicleOperators: Array<OrderVehicleOperator> = [];

    @observable
    public vehiclePrices: ObservableMap<string, number> = observable.map(
        VehicleTypes.reduce((result, vt: VehicleType) => {
            result[vt] = 0;
            return result;
        }, {}),
    );

    @action
    public addVehicle(vehicleType: VehicleType) {
        const addVehicle = () => {
            const price = this.vehiclePrices.get(vehicleType as unknown as string) as number;

            this.vehicleOperators.push(
                new OrderVehicleOperator({
                    enum: vehicleType,
                    data: {
                        simpleDrivers: this.data.simpleDrivers,
                    },
                    modules: null,
                    assignationOperator: undefined,
                    orderId: this.m._id,
                    destinationLocationId: this.m.destinationLocationId,
                    originLocationId: this.m.originLocationId,
                    price,
                    pricingCurrency: this.m.pricingCurrency,
                    passengers: this.m.passengers,
                    order: this.m,
                }),
            );
        };

        if (this.isEdited()) {
            addVehicle();
        } else {
            this.edit(() => {});
            addVehicle();
            this.save();
        }
    }

    private removeVehicleOperator(operatorIndex: number) {
        this.vehicleOperators = this.vehicleOperators.filter((_vo, i) => i !== operatorIndex);
    }

    @action
    public async removeVehicle(vehicleOperator: OrderVehicleOperator, index: number) {
        if (this.vehicleOperators.length === 1) {
            alert("There should be at least one vehicle");
            return;
        }

        if (vehicleOperator.assignationOperator !== undefined) {
            if (vehicleOperator.assignationOperator.isSaved) {
                await vehicleOperator.assignationOperator.cancel(VEHICLE_REMOVED_CANCELLATION_INFO);
            }
            vehicleOperator.assignationOperator = undefined;
        }

        if (this.isEdited()) {
            this.removeVehicleOperator(index);
        } else {
            this.edit(() => {});
            this.removeVehicleOperator(index);
            this.save();
        }
    }

    @action
    public async setVehicleType(vehicleOperator: OrderVehicleOperator, vehicleType: VehicleType) {
        if (vehicleOperator.assignationOperator !== undefined) {
            if (vehicleOperator.assignationOperator.isSaved) {
                await vehicleOperator.assignationOperator.cancel(VEHICLE_CHANGED_CANCELLATION_INFO);
            }

            vehicleOperator.assignationOperator = undefined;
        }

        if (this.isEdited()) {
            vehicleOperator.enum = vehicleType;
        } else {
            this.edit(() => {});
            vehicleOperator.enum = vehicleType;

            Promise.all([this.save(), this.retrieveVehiclesData()]);
        }
    }

    public async updateAssignation(operator: AssignationOperator): Promise<void> {
        try {
            await this.rpcClient.assignation.updateAssignation(operator.m._id, toJS(operator.m));
            operator.edit((a) => (!isUndefinedOrNull(a.version) ? (a.version += 1) : (a.version = 0)));
        } catch (e: any) {
            alert(
                "Unable to update assignation. It could be the result of someone updated this assignation meanwhile. Please, reload the page and try again.",
            );
            // eslint-disable-next-line no-console
            console.error(e);
        }
    }

    @action
    public async setRecommendedVehiclesConfiguration() {
        const wasEdited = this.isEdited();
        const recommendedVehicles = this.recommendedVehiclesConfiguration;

        this.edit((o) => (o.vehicles = recommendedVehicles));
        this.vehicleOperators = [];
        await this.retrieveVehiclesData();

        const assignationOperators = this.assignationOperators.filter((ao) => {
            const assignationStatus = plainToClass(Assignation, ao.m).status;
            return assignationStatus === AssignationStatus.Accepted || assignationStatus === AssignationStatus.Pending;
        });

        // tricky way to remove redundant assignations
        const assignationOperatorsIds = assignationOperators.map((ao) => ao.m._id);
        // eslint-disable-next-line guard-for-in
        for (const i in toJS(assignationOperatorsIds)) {
            const ao = assignationOperators[i];

            if (
                this.vehicleOperators
                    .filter((vo) => !!vo.assignationOperator)
                    .find((vo) => (vo.assignationOperator as AssignationOperator).m._id === ao.m._id) === undefined
            ) {
                await ao.cancel(VEHICLE_CHANGED_CANCELLATION_INFO);
                delete this.assignationOperators[i];
            }
        }

        this.assignationOperators = this.assignationOperators.filter((ao) => !!ao);

        this.edit((o) => (o.vehicles = recommendedVehicles));

        if (!wasEdited) {
            await this.save();
        }
    }

    @observable
    public assignationOperators: Array<AssignationOperator> = [];

    @computed
    public get previousAssignationOperators() {
        return this.assignationOperators.filter((ao) => ao.m.cancelledAt || ao.m.declinedAt);
    }

    @action
    public async retrieveVehiclesData() {
        if (this.assignationOperators.length === 0) {
            const [usersVehicles, vehiclesInfo, rentalVehicleModels, assignationPrices] = await Promise.all([
                this.retrieveUsersVehiclesInfoForAssignations(),
                this.retrieveVehiclesInfoForAssignations(),
                this.retrieveRentalVehicleModelsForAssignations(),
                this.retrievePricesForAssignations(),
            ]);

            if (this.data.assignations?.length) {
                this.assignationOperators = this.data.assignations.map((assignation) => {
                    const vehicleInfo = vehiclesInfo.find(
                        (vehicleAssignation) => vehicleAssignation._id === assignation.vehicleId,
                    );
                    let vehicleTitle;
                    if (vehicleInfo) {
                        vehicleTitle = vehicleInfo.title;
                    }

                    const driver = this.data.simpleDrivers!.find(
                        (simpleDriver: SimpleDriver) => simpleDriver._id === assignation.userId,
                    ) as SimpleDriver;
                    const ao = new AssignationOperator({
                        modelConstructor: Assignation,
                        model: assignation,
                        modules: null,
                        data: {
                            subsidies: this.data.subsidies,
                            compensations: this.data.compensations,
                            penalties: this.data.penalties,
                        },
                        driver,
                        vehicle: assignation.vehicleType,
                        vehicleTitle,
                        pricingCurrency: this.m.pricingCurrency,
                        price: assignationPrices[assignation._id],
                        availableVehicles: usersVehicles
                            .filter(
                                (v) => v.ownerUserId === assignation.userId || v.ownerUserId === driver.companyUserId,
                            )
                            .filter(
                                filterSuitableVehicleInfo(
                                    assignation.vehicleType,
                                    getRequiredCapacityForVehicle({
                                        vehicleType: assignation.vehicleType,
                                        order: this.m,
                                        vehiclePassengersCount:
                                            this.m.vehicles.length > 1
                                                ? this.m.passengers.filter((p) => p.assignationId === assignation._id)
                                                      .length
                                                : this.m.passengers.length,
                                    }),
                                    [this.originLocationCountry?._id || "", this.destinationLocationCountry?._id || ""],
                                ),
                            ),
                        afterSave: async () => {
                            await ao.updateAssignationPrice();
                        },
                    });

                    ao.customerFeedbackOperator = this.customerFeedbacks.find(
                        (cfo) => cfo.m.driverUserId === assignation.userId,
                    );

                    return ao;
                });
            }
            this.vehicleSeatsCount = 0;
            this.data.assignations.forEach(async (assignation) => {
                if (assignation.cancelledAt || assignation.declinedAt) {
                    return;
                }

                const vehicleInfo = vehiclesInfo.find(
                    (vehicleAssignation) => vehicleAssignation._id === assignation.vehicleId,
                );
                const rentalVehicleModel = rentalVehicleModels.find(
                    (m) => m._id === assignation.rentalVehicle?.modelId,
                );
                if (vehicleInfo) {
                    this.vehicleSeatsCount += vehicleInfo.seatsCount;
                } else if (rentalVehicleModel) {
                    this.vehicleSeatsCount += rentalVehicleModel.seatsCount;
                } else {
                    globalManagementLogger.error("No vehicle information.");
                }
            });
        }

        if (this.vehicleOperators.length === 0) {
            const orderVehiclesAssignations = transformAssignationsToVehicleTypeAssignationsPairs(
                this.m.vehicles,
                this.assignationOperators.map((ao) => ao.m),
            );
            this.vehicleOperators = await Promise.all(
                this.m.vehicles.map(async (vehicleType, index) => {
                    const vehicleTypeAssignationPair = orderVehiclesAssignations[index];

                    const currentAssignation = vehicleTypeAssignationPair.assignation as Assignation;
                    let assignationOperator;

                    if (currentAssignation) {
                        assignationOperator = this.assignationOperators.find(
                            (ao) => ao.m._id === currentAssignation._id,
                        );
                    }

                    const price = this.vehiclePrices.get(vehicleType.toString());

                    return new OrderVehicleOperator({
                        enum: vehicleType,
                        data: {
                            simpleDrivers: this.data.simpleDrivers,
                            subsidies: this.data.subsidies,
                            compensations: this.data.compensations,
                            penalties: this.data.penalties,
                        },
                        modules: null,
                        assignationOperator,
                        orderId: this.m._id,
                        originLocationId: this.m.originLocationId,
                        destinationLocationId: this.m.destinationLocationId,
                        price,
                        pricingCurrency: this.m.pricingCurrency,
                        passengers: this.m.passengers,
                        order: this.m,
                    });
                }),
            );
        }
    }

    private async retrieveUsersVehiclesInfoForAssignations() {
        if (!this.data.assignations?.length) {
            return [];
        }

        const vehicleOwnersIds: string[] = [];

        this.data.assignations.forEach((assignation) => {
            const driver = this.data.simpleDrivers!.find(
                (d: SimpleDriver) => d._id === assignation.userId,
            ) as SimpleDriver;

            // if company driver is assigned - we need to fetch company vehicles, or own vehicles otherwise
            if (driver.isCompanyDriver && driver.companyUserId) {
                vehicleOwnersIds.push(driver.companyUserId);
            } else {
                vehicleOwnersIds.push(assignation.userId);
            }
        });

        const usersVehicles = plainToClass(
            VehicleInfo,
            await this.rpcClient.vehicle.retrieveUsersVehiclesInfo(vehicleOwnersIds),
        );
        return usersVehicles;
    }

    private async retrieveVehiclesInfoForAssignations() {
        const assignationsWithVehiclesIds = this.data.assignations.filter((a) => a.vehicleId);
        if (!assignationsWithVehiclesIds?.length) {
            return [];
        }

        const vehiclesInfo = await this.rpcClient.vehicle.retrieveVehiclesInfo(
            assignationsWithVehiclesIds.map((a) => a.vehicleId as string),
        );
        return vehiclesInfo;
    }

    private async retrieveRentalVehicleModelsForAssignations() {
        const rentalVehicleModelIds = this.data.assignations
            .map((a) => a.rentalVehicle?.modelId)
            .filter((id): id is string => typeof id === "string");
        if (!rentalVehicleModelIds?.length) {
            return [];
        }

        try {
            const vehicleModels = await Promise.all(
                rentalVehicleModelIds.map((id) => this.rpcClient.vehicle.retrieveVehicleModel(id)),
            );
            return vehicleModels;
        } catch (error) {
            return [];
        }
    }

    private async retrievePricesForAssignations() {
        if (!this.data.assignations?.length) {
            return {};
        }
        const getPriceForAssignation = async (assignation: Assignation) => {
            const { price } = await this.rpcClient.assignation.retrieveAssignationPrice(assignation);
            return { _id: assignation._id, price };
        };
        const assignationPrices = await Promise.all(this.data.assignations.map(getPriceForAssignation));

        return assignationPrices.reduce(
            (acc, { _id, price }) => ({ ...acc, [_id]: price }),
            {} as Record<string, number>,
        );
    }

    public vehiclesReaction = reaction(
        () => JSON.stringify(this.vehicleOperators.map((vehicleOperator) => vehicleOperator.enum)),
        async () => {
            this.m.vehicles = this.vehicleOperators.map((vo) => vo.enum);

            // await this.calculatePrice();
            // await this.calculateFee();
            await this.retrieveVehiclesData();
        },
    );

    // route data

    public originDestinationReaction = reaction(
        () => [this.m.originLocationId, this.m.destinationLocationId],
        async () => {
            if (this.m.originLocationId && this.m.destinationLocationId) {
                this.isRoutesFetching = true;
                const maybeRoute = await this.retrieveRouteForOrder();
                this.setOrderRoute({ route: maybeRoute });
                await this.setLocationArrivalTimes();
                this.isRoutesFetching = false;
            }
        },
        {
            delay: 500,
            equals: (a, b) => {
                // It was reacting even on initialisation, so we had retrieveRouteForOrder/ executed twice on page load
                const isArrayInitial = Array.isArray(a) && a.every((v) => v === undefined);
                if (a === undefined || isArrayInitial) {
                    return true;
                }
                return comparer.structural(a, b);
            },
        },
    );

    @computed
    public get originLocation(): Location | undefined {
        return this.data.locations?.find((l) => l._id === this.m.originLocationId);
    }

    @computed
    public get destinationLocation(): undefined | Location {
        return this.data.locations?.find((l) => l._id === this.m.destinationLocationId);
    }

    @computed
    public get originLocationCountry(): SimpleCountry | undefined {
        return this.getCountryForLocation(this.originLocation);
    }

    @computed
    public get destinationLocationCountry(): SimpleCountry | undefined {
        return this.getCountryForLocation(this.destinationLocation);
    }

    @action
    public autoSelectPricingCountry() {
        if (!this.originLocationCountry || !this.destinationLocationCountry) {
            globalManagementLogger.error("Not enough data to autoselect pricing country.");
            return;
        }

        if (this.originLocationCountry.guidePrice > this.destinationLocationCountry.guidePrice) {
            this.m.pricingCountryId = this.destinationLocationCountry._id;
        } else {
            this.m.pricingCountryId = this.originLocationCountry._id;
        }
    }

    @observable
    public isPricesRecalculating: boolean = false;

    @observable
    public isPricesRecalculated: boolean = false;

    @action
    public async calculatePrices() {
        this.isPricesRecalculating = true;

        // update order location prices from route locations
        this.m.contentLocations = this.m.contentLocations.map((cl) => {
            const routeLocation = this.route?.locations.find((rl: RouteLocation) => rl.locationId === cl.locationId);

            if (routeLocation !== undefined) {
                routeLocation.vehicleTypesPricesFees.forEach((rvtpf) => {
                    cl.vehicleTypesPricesFees = cl.vehicleTypesPricesFees.map((cvtpf) => {
                        if (rvtpf.vehicleType === cvtpf.vehicleType && !cvtpf.isManuallyModified) {
                            cvtpf.price = rvtpf.price;
                            cvtpf.fee = rvtpf.fee;
                        }

                        return cvtpf;
                    });
                });
            }

            return cl;
        });

        await Promise.all([
            this.calculatePrice(),
            this.calculateFee(),
            this.calculateLocationsTravelData(this.m.contentLocations, this.m.customLocations),
        ]);

        this.isPricesRecalculating = false;
        this.isPricesRecalculated = true;
        setTimeout(() => {
            this.isPricesRecalculated = false;
        }, 3500);
    }

    @computed
    public get isOverPaid(): boolean {
        return this.sumOfFulfilledPayments > this.totalPrice;
    }

    @computed
    public get isFullyPaid(): boolean {
        return this.sumOfFulfilledPayments === this.totalPrice;
    }

    @computed
    public get isCoveredByPayments(): boolean {
        return this.sumOfFulfilledPayments + this.sumOfCashPendingPayments === this.totalPrice;
    }

    @computed
    public get sumOfFulfilledPayments(): number {
        return this.paymentOperators.reduce((sum: number, p) => sum + p.fulfilledAmount, 0);
    }

    @computed
    public get sumOfCashPendingPayments(): number {
        return this.paymentOperators
            .filter((po) => po.m.type === PaymentType.Cash && po.m.status === PaymentStatus.Pending)
            .reduce((sum: number, p) => sum + p.m.amount, 0);
    }

    @computed
    public get sumOfChargebacksWithoutPayment(): number {
        return this.chargebackOperators.reduce(
            (sum: number, o) => sum + (o.m.status === ChargebackStatus.Fulfilled ? o.m.amount : 0),
            0,
        );
    }

    @computed
    public get discountsPrice(): number {
        return this.orderDiscounts.reduce((prev: number, d: Discount) => prev + d.price, 0) ?? 0;
    }

    @computed
    public get discountsFee(): number {
        return this.orderDiscounts.reduce((prev: number, d: Discount) => prev + d.fee, 0) ?? 0;
    }

    @computed
    public get totalDiscountedPrice(): number {
        return this.price - this.discountsPrice;
    }

    @computed
    public get totalDiscountedFee(): number {
        return this.fee - this.discountsFee;
    }

    @observable
    public priceCalculationTimer?: ReturnType<typeof setTimeout>;

    @computed
    public get totalPrice(): number {
        return Math.round(this.price + this.fee);
    }

    @observable
    public price: number = 0;

    public currency: Currency = Currency.Euro;

    public currencyRate: number = 1;

    @observable
    public lastModelPriceJSON: string | undefined;

    @observable
    public locationArrivalTimes: LocationArrival[] = [];

    @action
    public async calculatePrice() {
        if (JSON.stringify(toJS(this.m)) === this.lastModelPriceJSON) {
            return;
        }

        this.lastModelPriceJSON = JSON.stringify(toJS(this.m));

        if (!this.originLocation || !this.destinationLocation || !this.m.vehicles?.length) {
            return;
        }

        const setTimer = () => {
            this.priceCalculationTimer = setTimeout(async () => {
                await validateWithoutLogging(Order, this.m, OrderPriceCalculationValidationOptions);
                this.price = PriceCalculator.calculateOrderPrice(this.m, this.orderDiscounts);
                const orderVehicles = toJS(this.m.vehicles);

                // eslint-disable-next-line guard-for-in
                for (const vi in orderVehicles) {
                    const vehicle = orderVehicles[vi];

                    const vehicleOrder = { ...this.m };
                    vehicleOrder.vehicles = [vehicle];
                    await validateWithoutLogging(Order, vehicleOrder as Order, OrderPriceCalculationValidationOptions);
                    this.vehiclePrices.set(
                        vehicle as unknown as string,
                        PriceCalculator.calculateOrderPrice(vehicleOrder as Order, this.orderDiscounts),
                    );
                }

                this.vehicleOperators.forEach((vo) => {
                    vo.price = this.vehiclePrices.get(vo.enum as unknown as string) as number;
                });
            }, PRICE_FEE_CALCULATION_DEBOUNCE);
        };

        if (this.priceCalculationTimer != null) {
            clearTimeout(this.priceCalculationTimer);
        }

        setTimer();
    }

    @observable
    public feeCalculationTimer?: ReturnType<typeof setTimeout>;

    @observable
    public fee: number = 0;

    @observable
    public lastModelFeeJSON: string | undefined;

    @action
    public async calculateFee() {
        if (JSON.stringify(toJS(this.m)) === this.lastModelFeeJSON) {
            return;
        }

        this.lastModelFeeJSON = JSON.stringify(toJS(this.m));

        if (!this.originLocation || !this.destinationLocation || !this.m.vehicles?.length) {
            return;
        }

        const setTimer = () => {
            this.feeCalculationTimer = setTimeout(async () => {
                await validateWithoutLogging(Order, this.m, OrderPriceCalculationValidationOptions);
                this.fee = PriceCalculator.calculateOrderFee(this.m, this.orderDiscounts);
            }, PRICE_FEE_CALCULATION_DEBOUNCE);
        };

        if (!isUndefinedOrNull(this.feeCalculationTimer)) {
            clearTimeout(this.feeCalculationTimer);
        }

        setTimer();
    }

    private setPrice() {
        this.price = this.m.price || 0;
    }

    private setFee() {
        this.fee = this.m.fee || 0;
    }

    private async setVehiclePrices() {
        const orderVehicles = toJS(this.m.vehicles);

        // eslint-disable-next-line guard-for-in
        for (const vi in orderVehicles) {
            const vehicle = orderVehicles[vi];

            const vehicleOrder = { ...this.m };
            vehicleOrder.vehicles = [vehicle];
            await validateWithoutLogging(Order, vehicleOrder as Order, OrderPriceCalculationValidationOptions);
            this.vehiclePrices.set(
                vehicle as unknown as string,
                PriceCalculator.calculateOrderPrice(vehicleOrder as Order, this.orderDiscounts),
            );
        }

        this.vehicleOperators.forEach((vo) => {
            vo.price = this.vehiclePrices.get(vo.enum as unknown as string) as number;
        });
    }

    public priceReaction = reaction(
        () =>
            JSON.stringify(this.m.vehicleTypesPricesFees) +
            this.m.additionalPrice +
            this.m.additionalFee +
            this.m.tollPrice +
            this.m.tollFee,
        async () => {
            await Promise.all([this.setPrice(), this.setFee(), this.setVehiclePrices()]);
        },
    );

    @action
    public async updateAdditionalPrice(value: number) {
        if (this.pricingCountry) {
            const feeCoefficient = getDefaultFeeCoefficient(this.pricingCountry);
            const fee = PriceCalculator.calculateFeeFromPrice(value, feeCoefficient);

            this.edit((o) => {
                o.additionalFee = fee;
                o.additionalPrice = value;
            });

            await Promise.all([this.calculatePrice(), this.calculateFee()]);
        }
    }

    @action
    public async updateAdditionalFee(value: number) {
        this.edit((o) => (o.additionalFee = value));
        await Promise.all([this.calculatePrice(), this.calculateFee()]);
    }

    @action
    public async updateTollPrice(value: number) {
        if (this.pricingCountry) {
            const feeCoefficient = getDefaultFeeCoefficient(this.pricingCountry);
            const { price, fee } = PriceCalculator.calculatePriceAndFeeFromValue(value, feeCoefficient);

            this.edit((o) => {
                o.tollPrice = price;
                o.tollFee = fee;
            });
            await Promise.all([this.calculatePrice(), this.calculateFee()]);
        }
    }

    @action
    public async updateVehicleTypePrice(vehicleType: VehicleType, value: number) {
        if (this.pricingCountry) {
            const vehicleTypePriceFeeIndex = this.m.vehicleTypesPricesFees.findIndex(
                (vtpf) => vtpf.vehicleType === vehicleType,
            );

            const { price, fee } = PriceCalculator.calculatePriceAndFeeFromValue(
                value,
                getVehicleTypeFeeCoefficient(this.pricingCountry, vehicleType),
            );

            this.edit((o) => {
                o.vehicleTypesPricesFees[vehicleTypePriceFeeIndex].price = price;
                o.vehicleTypesPricesFees[vehicleTypePriceFeeIndex].fee = fee;

                o.vehicleTypesPricesFees[vehicleTypePriceFeeIndex].isManuallyModified = true;
            });

            await this.calculatePrice();
            await this.calculateFee();
        }
    }

    @action
    public async addVTPF(vehicleType: VehicleType) {
        this.edit((o) => {
            o.vehicleTypesPricesFees[o.vehicleTypesPricesFees.length] = observable({
                vehicleType,
                price: 0,
                fee: 0,
                isManuallyModified: false,
            } as VehicleTypePriceFee);
        });

        this.edit((o) => {
            o.vehicleTypesPricesFees[0].price -= 1;
            o.vehicleTypesPricesFees[0].price += 1;
        });
    }

    @action
    public async updateVehicleTypeFee(vehicleType: VehicleType, fee: number) {
        const vehicleTypePriceFeeIndex = this.m.vehicleTypesPricesFees.findIndex(
            (vtpf) => vtpf.vehicleType === vehicleType,
        );

        this.edit((o) => {
            o.vehicleTypesPricesFees[vehicleTypePriceFeeIndex].fee = fee;
            o.vehicleTypesPricesFees[vehicleTypePriceFeeIndex].isManuallyModified = true;
        });
    }

    @observable
    public route?: Route;

    @computed
    public get pricingCountry(): SimpleCountry | undefined {
        return this.data.simpleCountries?.find((sc) => sc._id === this.m.pricingCountryId);
    }

    @action
    public setPricingCountryId(id: string) {
        // reset region in case of different country
        if (id !== this.model.pricingCountryId) {
            this.setPricingRegionId(undefined);
        }

        this.m.pricingCountryId = id;
    }

    @computed
    public get pricingRegion(): Region | undefined {
        return this.data.regions?.find((r) => r._id === this.m.pricingRegionId);
    }

    @action
    public setPricingRegionId(id?: string) {
        this.m.pricingRegionId = id;
    }

    @observable
    public isRoutesFetching: boolean;

    @action
    public async recalculatePrices() {
        const route = this.createCustomRoute();

        let calculatedRoute: Route | undefined;

        try {
            calculatedRoute = await this.rpcClient.content.calculateRoutePrices(route, false);
        } catch (e: any) {
            globalManagementLogger.error(e);
            alert("Unable to calculate travelling data for this route.");
        }

        if (calculatedRoute) {
            this.route = calculatedRoute;
            if (this.isEdited()) {
                this.edit((o) => (o.vehicleTypesPricesFees = calculatedRoute!.vehicleTypesPricesFees));
            }
        } else {
            this.route = route;
        }

        this.m.routeId = undefined;
        await this.calculatePrices();
    }

    private createCustomRoute() {
        const route = new Route();
        route._id = "";
        route.originLocationId = this.m.originLocationId;
        route.destinationLocationId = this.m.destinationLocationId;

        if (this.m.pricingCountryId) {
            route.pricingCountryId = this.m.pricingCountryId;
        } else if (this.originLocationCountry && this.destinationLocationCountry) {
            const pricingCountryId = (
                this.originLocationCountry.guidePrice > this.destinationLocationCountry.guidePrice
                    ? this.destinationLocationCountry
                    : this.originLocationCountry
            )._id;
            this.setPricingCountryId(pricingCountryId);
            route.pricingCountryId = pricingCountryId;
        }

        if (this.m.pricingRegionId) {
            route.pricingRegionId = this.m.pricingRegionId;
        }
        return route;
    }

    @action
    public async setCustomRoute() {
        const route = this.createCustomRoute();
        this.route = route;
        this.m.routeId = undefined;
        await this.calculatePrices();
    }

    @action
    public setOrderRoute({ route, isInitialFetch }: { route?: Route; isInitialFetch?: boolean }) {
        const routePricingPriority = route && !isInitialFetch;
        const pricingRegionId = routePricingPriority
            ? route?.pricingRegionId ?? this.m.pricingRegionId
            : this.m.pricingRegionId ?? route?.pricingRegionId;
        this.setPricingRegionId(pricingRegionId);

        if (route) {
            this.route = route;
            const pricingCountryId = routePricingPriority
                ? route?.pricingCountryId ?? this.m.pricingCountryId
                : this.m.pricingCountryId ?? route?.pricingCountryId;
            this.setPricingCountryId(pricingCountryId);
        } else {
            // set custom route
            this.setCustomRoute();
            this.locationArrivalTimes = [];
        }

        if (this.route) {
            if (this.newOrder) {
                this.m.vehicleTypesPricesFees.forEach((vtpf, i) => {
                    const vehicleTypeIndex = this.route!.vehicleTypesPricesFees.findIndex(
                        (v) => v.vehicleType === vtpf.vehicleType,
                    );

                    if (
                        !vtpf.isManuallyModified &&
                        !isUndefinedOrNull(this.route!.vehicleTypesPricesFees[vehicleTypeIndex])
                    ) {
                        this.m.vehicleTypesPricesFees[i].price = Math.round(
                            this.route!.vehicleTypesPricesFees[vehicleTypeIndex].price,
                        );
                        this.m.vehicleTypesPricesFees[i].fee = Math.round(
                            this.route!.vehicleTypesPricesFees[vehicleTypeIndex].fee,
                        );
                    }
                });

                this.m.tollPrice = this.route.tollPrice;
                this.m.tollFee = this.route.tollFee;

                this.m.additionalPrice = this.route.additionalPrice;
                this.m.additionalFee = this.route.additionalFee;

                this.m.pricingCurrency = this.route.pricingCurrency;
            }

            this.m.routeId = this.route._id || undefined;
        }
    }

    /**
     * Due to how we store content locations in the order as another operators
     * We need to ensure we have the latest data from the operators, the reactions are not stable enough
     * Use this computed method instead of accessing the order directly to ensure you have the latest data
     */
    @computed get currentContentLocations() {
        return this.contentLocationOperators.map((operator) => operator.m);
    }

    /**
     * Due to how we store custom locations in the order as another operators
     * We need to ensure we have the latest data from the operators, the reactions are not stable enough
     * Use this computed method instead of accessing the order directly to ensure you have the latest data
     */
    @computed get currentCustomLocations() {
        return this.customLocationOperators.map((operator) => operator.m);
    }

    @action
    public async setLocationArrivalTimes() {
        if (this.m.originLocationId && this.m.destinationLocationId && this.m.departureAt) {
            const travelEstimates = await this.rpcClient.travelEstimates.calculate({
                originLocationId: this.m.originLocationId,
                destinationLocationId: this.m.destinationLocationId,
                contentLocations: this.currentContentLocations,
                customLocations: this.currentCustomLocations,
                departureAt: this.m.departureAt,
                pickupPosition: this.m.pickupPosition,
                dropoffPosition: this.m.dropoffPosition,
                offerType: this.m.offerType,
            });

            if (!travelEstimates) {
                alert("Unable to calculate travelling data for this route.");
            }

            this.locationArrivalTimes = travelEstimates?.locationArrivals || [];
        } else {
            this.locationArrivalTimes = [];
        }
    }

    private async retrieveRouteForOrder(): Promise<Route | undefined> {
        const retrieveRoutesOptions = new RetrieveRoutesOptions();
        retrieveRoutesOptions.isLive = true;
        retrieveRoutesOptions.isBidirectional = undefined;
        retrieveRoutesOptions.originLocationIds = [this.m.originLocationId, this.m.destinationLocationId];
        retrieveRoutesOptions.destinationLocationIds = [this.m.destinationLocationId, this.m.originLocationId];

        const routes = await this.rpcClient.content.retrieveRoutes(retrieveRoutesOptions);
        const [route] = plainToClass(Route, routes).filter(
            (r) =>
                (r.originLocationId === this.m.originLocationId &&
                    r.destinationLocationId === this.m.destinationLocationId) ||
                (r.isBidirectional &&
                    r.originLocationId === this.m.destinationLocationId &&
                    r.destinationLocationId === this.m.originLocationId),
        );
        return route;
    }

    private async fetchPricingCurrency(pricingCountryId: string) {
        const { pricingCurrency } = await this.rpcClient.content.retrieveCountry(pricingCountryId);
        return pricingCurrency;
    }

    @action private async updatePricingCurrency() {
        // pricing currency defaults to Euro
        let { pricingCurrency } = this.m;
        // when the pricing country is the same as the one on the route, give the route's pricing currency the priority
        if (this.route && this.route._id && this.route.pricingCountryId === this.m.pricingCountryId) {
            pricingCurrency = this.route.pricingCurrency;
        } else if (this.m.pricingCountryId) {
            // case for custom route OR when the pricing country of route and selected one differs
            // populate pricing currency based on the pricing country
            pricingCurrency = await this.fetchPricingCurrency(this.m.pricingCountryId);
        }

        if (this.m.pricingCurrency === pricingCurrency) {
            return;
        }

        runInAction(() => {
            this.m.pricingCurrency = pricingCurrency;
        });
    }

    @observable
    public isDepartureAtDateTimePickerOpened: boolean = false;

    @action
    public orderOpenDepartureAtDateTimePicker() {
        this.isDepartureAtDateTimePickerOpened = true;
    }

    @action
    public orderCloseDepartureAtDateTimePicker() {
        this.isDepartureAtDateTimePickerOpened = false;
    }

    @computed
    public get departureAtUTC(): Date | undefined {
        return this.originLocation
            ? transformBookingToUTC(this.m.departureAt, this.originLocation.timezone)
            : undefined;
    }

    @computed
    public get untilDepartureHours(): number | undefined {
        return this.departureAtUTC ? differenceInHours(this.departureAtUTC, new Date()) : undefined;
    }

    @computed
    public get changesCanBeGuaranteed(): boolean {
        return this.untilDepartureHours ? this.untilDepartureHours >= CHANGES_CANNOT_BE_GUARANTEED_IN_HOURS : true;
    }

    @computed
    public get shouldShowTripChangesAlert(): boolean {
        return (
            ![OrderStatus.Draft, OrderStatus.Pending].includes(this.m.status) &&
            !!this.latestChange &&
            this.fetchDataStatus === FetchDataStatus.Success &&
            !!this.data.simpleLocations &&
            !!this.data.simpleCountries
        );
    }

    @computed
    public get isOrderConfirmed(): boolean {
        return OrderStatus.Confirmed === this.m.status;
    }

    @computed
    public get isOrderPassed(): boolean {
        return this.untilDepartureHours ? this.untilDepartureHours < 0 : false;
    }

    @computed
    public get showChangesCantBeGuaranteed(): boolean {
        return !this.changesCanBeGuaranteed && this.isOrderConfirmed && !this.isOrderPassed;
    }

    @computed
    public get availableSpecialPickUpLocations(): boolean {
        return !!this.meetingPositions?.length;
    }

    @computed
    public get chosenFromSpecialPickUpLocations(): boolean {
        return !!this.meetingPositions?.map((position) => position._id).includes(this.m.meetingPositionId as string);
    }

    @computed
    public get timezoneDifferenceHours(): number | undefined {
        if (!this.originLocation || !this.departureAtUTC) {
            return undefined;
        }
        const offsetMin = offsetBookingToLocalMin(this.departureAtUTC, this.originLocation.timezone);
        return offsetMin / 60;
    }

    @observable
    public contentLocationOperators: Array<ContentLocationOperator> = [];

    @observable
    public vehicleSeatsCount: number = 0;

    @observable
    public customLocationOperators: Array<CustomLocationOperator> = [];

    @computed
    public get allLocations(): Array<CustomLocationOperator | ContentLocationOperator> {
        return [...this.contentLocationOperators, ...this.customLocationOperators].sort((a, b) =>
            a.m.order > b.m.order ? 1 : -1,
        );
    }

    @observable
    public openedContentLocations: Array<string> = [];

    @action
    public orderContentLocationClose(locationId: string) {
        const newOpenedLocations: Array<string> = this.openedContentLocations;

        newOpenedLocations.splice(newOpenedLocations.indexOf(locationId), 1);
        this.openedContentLocations = newOpenedLocations;
    }

    @action
    public orderContentLocationOpen(locationId: string) {
        this.openedContentLocations = [...this.openedContentLocations, locationId];
    }

    @observable
    public openedCustomLocations: Array<string> = [];

    @action
    public orderCustomLocationClose(locationTitle: string) {
        const newOpenedLocations: Array<string> = this.openedCustomLocations;

        newOpenedLocations.splice(newOpenedLocations.indexOf(locationTitle), 1);
        this.openedCustomLocations = newOpenedLocations;
    }

    @action
    public orderCustomLocationOpen(locationTitle: string) {
        this.openedCustomLocations = [...this.openedCustomLocations, locationTitle];
    }

    @action
    public orderContentLocationSetOrder(locationId: string, order: number) {
        let newLocations = this.contentLocationOperators.slice().sort((a, b) => (a.m.order > b.m.order ? 1 : -1));
        let newCustomLocations = this.customLocationOperators.slice().sort((a, b) => (a.m.order > b.m.order ? 1 : -1));
        const updatingLocationOrder = newLocations.find((location) => location.m.locationId === locationId)!.m.order;

        newLocations = newLocations.map((location: ContentLocationOperator) => {
            if (location.m.order === order) {
                if (updatingLocationOrder < order) {
                    // order increased - moving down
                    location.orderDown();
                } else if (updatingLocationOrder > order) {
                    // order decreased - moving up
                    location.orderUp();
                }
            }

            if (location.m.locationId === locationId) {
                location.edit((cl) => (cl.order = order));
            }

            return location;
        });

        newCustomLocations = newCustomLocations.map((customLocation: CustomLocationOperator) => {
            if (customLocation.m.order === order) {
                // order increased - moving down
                if (updatingLocationOrder < order) {
                    customLocation.orderDown();
                } else if (updatingLocationOrder > order) {
                    // order decreased - moving up
                    customLocation.orderUp();
                }
            }

            return customLocation;
        });

        this.contentLocationOperators = newLocations;
        this.customLocationOperators = newCustomLocations;
        this.setLocationArrivalTimes();
    }

    @action
    public orderCustomLocationSetOrder(locationTitle: string, order: number) {
        let newLocations = this.contentLocationOperators.slice().sort((a, b) => (a.m.order > b.m.order ? 1 : -1));
        let newCustomLocations = this.customLocationOperators.slice().sort((a, b) => (a.m.order > b.m.order ? 1 : -1));
        const updatingLocationOrder: number = (
            newCustomLocations.find((location) => location.m.title === locationTitle) as CustomLocationOperator
        ).m.order;

        newCustomLocations = newCustomLocations.map((customLocation: CustomLocationOperator) => {
            if (customLocation.m.order === order) {
                // order increased - moving down
                if (updatingLocationOrder < order) {
                    customLocation.orderDown();
                } else if (updatingLocationOrder > order) {
                    // order decreased - moving up
                    customLocation.orderUp();
                }
            }

            if (customLocation.m.title === locationTitle) {
                customLocation.edit((cl) => (cl.order = order));
            }

            return customLocation;
        });

        newLocations = newLocations.map((location: ContentLocationOperator) => {
            if (location.m.order === order) {
                // order increased - moving down
                if (updatingLocationOrder < order) {
                    location.orderDown();
                } else if (updatingLocationOrder > order) {
                    // order decreased - moving up
                    location.orderUp();
                }
            }

            return location;
        });

        this.contentLocationOperators = newLocations;
        this.customLocationOperators = newCustomLocations;
        this.setLocationArrivalTimes();
    }

    @computed
    public get isEnoughSpaceForLuggage(): boolean {
        return isEnoughSpaceForLuggage(
            this.m.vehicles,
            this.passengers.map((po) => po.m),
        );
    }

    @computed
    public get isEnoughSpaceForPassengers(): boolean {
        const activeAssignation = this.data.assignations.filter(
            (assignation) => !assignation.declinedAt && !assignation.cancelledAt,
        );
        if (this.vehicleSeatsCount !== 0 && activeAssignation.length > 0) {
            const vehicleHasEnoughSeats = this.vehicleSeatsCount >= this.passengers.length;
            return vehicleHasEnoughSeats;
        }
        const capacity = calculateCapacityOfVehicles(this.m.vehicles);
        const vehicleHasEnoughSeats = capacity >= this.passengers.length;
        return vehicleHasEnoughSeats;
    }

    @computed
    public get recommendedVehiclesConfiguration(): Array<VehicleType> | undefined {
        const orderVehicles = toJS(this.m.vehicles);

        const recommendedVehicles = calculateOptimalVehiclesForPassengers(
            this.passengers.map((po) => po.m),
            !!this.m.rideId,
        );

        // luxury sedan is replaced by regular sedan for this comparison
        const vehiclesWOLuxurySedan = orderVehicles
            .slice(0)
            .map((v: VehicleType) => (v === VehicleType.LuxurySedan ? VehicleType.Sedan : v));

        if (JSON.stringify(vehiclesWOLuxurySedan.sort()) !== JSON.stringify(recommendedVehicles.sort())) {
            return recommendedVehicles;
        }

        return undefined;
    }

    @action
    public getCompletePriceForVehicle(vt: VehicleType): number {
        const vehicleTypeIndex = this.m.vehicleTypesPricesFees.findIndex((vtpf) => vtpf.vehicleType === vt);

        let basePrice = this.m.vehicleTypesPricesFees[vehicleTypeIndex].price;

        if (!isUndefinedOrNull(this.contentLocationOperators)) {
            basePrice += this.contentLocationOperators.reduce((amount: number, clo: ContentLocationOperator) => {
                const l = clo.m;
                return (
                    amount +
                    l.waitingPrice +
                    l.entrancePrice +
                    l.parkingPrice +
                    l.additionalPrice +
                    l.tollPrice +
                    l.vehicleTypesPricesFees[vehicleTypeIndex].price
                );
            }, 0);
        }

        if (!isUndefinedOrNull(this.customLocationOperators)) {
            basePrice += this.customLocationOperators.reduce((amount: number, clo: CustomLocationOperator) => {
                const l = clo.m;
                return amount + l.waitingPrice + l.vehicleTypesPricesFees[vehicleTypeIndex].price;
            }, 0);
        }

        return basePrice;
    }

    public passengersReaction = reaction(
        () => JSON.stringify(this.passengers.map((passengerOperator) => toJS(passengerOperator.m))),
        async () => {
            if (this.isEdited()) {
                this.edit((o) => (o.passengers = this.passengers.map((po) => po.m)));
            }
        },
        { delay: 100 },
    );

    @computed
    public get passengersLoaded(): boolean {
        if (isUndefinedOrNull(this.passengers) || !this.passengers?.length) {
            return false;
        }

        if (this.passengers.every((passenger) => !passenger.m.userId)) {
            return false;
        }

        return true;
    }

    @action
    public populatePassengersOperators() {
        this.passengers = this.m.passengers.map(
            (p) =>
                new PassengerOperator({
                    modelConstructor: Passenger,
                    model: p,
                    modules: {},
                    data: {},
                }),
        );
    }

    @action
    public addPassenger(type = PassengerType.Adult) {
        const passenger = new Passenger();
        passenger.type = type;
        passenger.phoneNumber = "";

        const lead = this.passengers.find((po) => po.m.type === PassengerType.Lead);
        if (!isUndefinedOrNull(lead)) {
            passenger.countryId = lead.m.countryId;
        }

        this.passengers.push(
            new PassengerOperator({
                modelConstructor: Passenger,
                model: passenger,
                modules: {},
                data: {},
            }),
        );
    }

    @action
    public removeLastPassenger() {
        const lastIndex = this.passengers.length - 1;

        if (this.passengers[lastIndex].m.type !== PassengerType.Lead) {
            this.passengers = this.passengers.filter((pax) => this.passengers.indexOf(pax) !== lastIndex);
        }
    }

    @action
    public removePassenger(index: number) {
        this.passengers = this.passengers.filter((pax) => this.passengers.indexOf(pax) !== index);
    }

    @observable
    public orderDiscounts: Array<Discount> = [];

    @observable
    public newDiscountOperator?: DiscountOperator;

    @action
    public async fetchOrderDiscounts() {
        if (!this.m.discountIds) {
            return;
        }

        this.orderDiscounts = await this.rpcClient.order.retrieveDiscounts({ ids: this.m.discountIds });
    }

    public discountsReaction = reaction(
        () => this.m.discountIds.length,
        () => {
            this.fetchOrderDiscounts();

            // this.calculatePrice();
            // this.calculateFee();
        },
    );

    @action
    private async addDiscount(newDiscountId: string, isNewlyCreated?: boolean) {
        const plainOrder = classToPlain(this.m) as Order;
        plainOrder.discountIds.push(newDiscountId);

        const { price, fee } = plainOrder;
        if (price === undefined || fee === undefined) {
            throw new Error("Price or fee is undefined");
        }

        if (price < 0 || fee < 0) {
            await this.rpcClient.order.removeDiscount(newDiscountId);
        }

        const alertDiscountWording = isNewlyCreated ? "new" : "chosen";
        const alertFixOptionWording = isNewlyCreated
            ? "change price or fee of the discount"
            : "consider adding another discount or create a new one";

        if (price < 0) {
            alert(`Order price with ${alertDiscountWording} discount is negative. Please, ${alertFixOptionWording}.`);
            return;
        }

        if (fee < 0) {
            alert(`Order fee with ${alertDiscountWording} discount is negative. Please, ${alertFixOptionWording}.`);
            return;
        }

        const isInEditMode = this.isEdited();

        this.edit((o) => o.discountIds.push(newDiscountId));

        if (!isInEditMode) {
            await this.save();
        }

        this.newDiscountOperator = undefined;
    }

    @action
    public async addNewDiscount() {
        if (this.newDiscountOperator) {
            await this.newDiscountOperator.validate();

            await this.newDiscountOperator.save();
            await this.addDiscount(this.newDiscountOperator.m._id, true);
        }
    }

    @action
    public async setAddingNewDiscount() {
        const discount = new Discount();
        this.newDiscountOperator = new DiscountOperator({
            modelConstructor: Discount,
            model: discount,
            data: {},
            modules: {},
            onSave: async (model: Discount) => {
                model.type = DiscountType.Manual;
                const discountId = await this.rpcClient.order.createDiscount(model);
                this.newDiscountOperator!.edit((d) => (d._id = discountId));
            },
        });

        if (this.m.totalPrice) {
            discount.fee = this.newDiscountOperator.calculateFeeDiscount(this.m.totalPrice);
        }
    }

    @action
    public unsetAddingNewDiscount() {
        this.newDiscountOperator = undefined;
    }

    @action
    public async removeDiscount(discountId: string) {
        const isInEditMode = this.isEdited();
        this.edit((o) => (o.discountIds = o.discountIds.filter((id) => id !== discountId)));

        if (!isInEditMode) {
            await this.save();
        }
    }

    @computed
    public get discountDivisionValidations(): Array<ValidationError> {
        const validationErrors: Array<ValidationError> = [];

        this.orderDiscounts.forEach((orderDiscount) => {
            const discountTotal = orderDiscount.price + orderDiscount.fee;

            if (discountTotal % this.m.vehicles.length > 0) {
                const ve = new ValidationError();
                ve.target = orderDiscount;
                ve.property = "price";
                ve.value = orderDiscount.price;
                ve.constraints = {};
                // eslint-disable-next-line max-len
                ve.constraints.division = `Sum of prices and fees of discounts cannot be divided by amount of drivers (${this.m.vehicles.length})`;
                validationErrors.push(ve);
            }
        });

        return validationErrors;
    }

    @observable
    public paymentOperators: Array<PaymentOperator> = [];

    @observable
    public paymentRequestOperators: Array<PaymentRequestOperator> = [];

    @observable
    public chargebackOperators: Array<ChargebackOperator> = [];

    @action
    public async retrieveOrderPayments() {
        const paymentData = await this.rpcClient.payment.retrievePaymentDataForOrderPage({
            orderIds: [this.m._id],
        });

        const paymentRequests = plainToClass(PaymentRequest, paymentData.paymentRequests);
        const payments = plainToClass(Payment, paymentData.payments);
        const chargebacks = plainToClass(Chargeback, paymentData.chargebacks);
        const validationInfo = paymentData.validationInfo;

        const chargebackOperators: Array<ChargebackOperator> = chargebacks.map(
            (cb) =>
                new ChargebackOperator({
                    modelConstructor: Chargeback,
                    model: cb,
                    modules: null,
                    data: null,
                }),
        );

        this.paymentRequestOperators = paymentRequests.map(
            (pr) =>
                new PaymentRequestOperator({
                    modelConstructor: PaymentRequest,
                    model: pr,
                    modules: null,
                    data: null,
                }),
        );

        if (payments?.length) {
            this.paymentOperators = payments.map((payment) => {
                const paymentRequest = paymentRequests.find((pr) => pr._id === payment.paymentRequestId);

                let isChargebackEnabled = false;

                try {
                    isChargebackEnabled = !!validationInfo[payment._id];
                } catch (e: any) {
                    globalManagementLogger.error(e);
                }

                const paymentOperator = new PaymentOperator({
                    modelConstructor: Payment,
                    model: payment,
                    paymentRequest,
                    modules: null,
                    data: {
                        isChargebackEnabled,
                    },
                    chargebacks: chargebackOperators.filter((ch) => ch.m.paymentId === payment._id),
                    onUpdate: this.reloadPayments,
                });

                paymentOperator.chargebackAmount = payment.amount;
                return paymentOperator;
            });
        }

        this.chargebackOperators = chargebackOperators.filter((ch) => !!ch.m.paymentId);
    }

    @action
    public async reloadPayments() {
        this.paymentOperators = [];
        this.paymentRequestOperators = [];
        this.chargebackOperators = [];

        await this.retrieveOrderPayments();
        await this.fetchOrder();
    }

    @observable
    public isPaymentRequestFormVisible: boolean = false;

    @observable
    public paymentRequestOperator?: PaymentRequestOperator;

    @action
    public revealPaymentRequestCreation(): void {
        this.isPaymentRequestFormVisible = true;

        const newPaymentRequest = new PaymentRequest();
        newPaymentRequest.userId = this.m.userId;
        newPaymentRequest.orderId = this.m._id;
        newPaymentRequest.currency = this.m.pricingCurrency;
        newPaymentRequest.pricingCountryId = this.m.pricingCountryId;

        this.paymentRequestOperator = new PaymentRequestOperator({
            modelConstructor: PaymentRequest,
            model: newPaymentRequest,
            modules: null,
            data: null,
            validateOptions: { skipMissingProperties: true },
            onSave: async (model: PaymentRequest) => {
                await this.rpcClient.payment.createPaymentRequest(toJS(model));
                await this.reloadPayments();
                this.hidePaymentRequestCreation();
            },
        });
    }

    @action
    public hidePaymentRequestCreation(): void {
        this.isPaymentRequestFormVisible = false;
        this.paymentRequestOperator = undefined;
    }

    @observable
    public isPaymentCreationFormVisible: boolean = false;

    @observable
    public paymentOperator?: PaymentOperator;

    @action
    public revealPaymentCreation(): void {
        this.isPaymentCreationFormVisible = true;

        const newPayment = new Payment();
        newPayment.userId = this.m.userId;
        newPayment.orderId = this.m._id;
        newPayment.currency = this.m.pricingCurrency;
        newPayment.isRecord = true;

        this.paymentOperator = new PaymentOperator({
            modelConstructor: Payment,
            model: newPayment,
            modules: null,
            data: {
                isChargebackEnabled: false,
            },
            validateOptions: { skipMissingProperties: true },
            onSave: async (editedModel) => {
                if (editedModel.type === PaymentType.Other && !editedModel.description) {
                    return;
                }

                await this.rpcClient.payment.createPayment(editedModel);
                await this.reloadPayments();
                this.hidePaymentCreation();
            },
            onUpdate: this.reloadPayments,
        });
    }

    @action
    public hidePaymentCreation(): void {
        this.isPaymentCreationFormVisible = false;
        this.paymentOperator = undefined;
    }

    @observable
    public isChargebackCreationFormVisible: boolean = false;

    @observable
    public orderChargebackInProgress: boolean = false;

    @observable
    public chargebackOperator?: ChargebackOperator;

    @action
    public setChargebackByPaymentDifference() {
        const newChargeback = new Chargeback();
        newChargeback.orderId = this.m._id;
        newChargeback.amount = this.sumOfFulfilledPayments - this.totalPrice;
        newChargeback.currency = this.m.pricingCurrency;

        if (this.paymentOperators.some((po) => po.status === PaymentStatus.Fulfilled)) {
            newChargeback.paymentId = (
                this.paymentOperators.find((po) => po.status === PaymentStatus.Fulfilled) as PaymentOperator
            ).m._id;
        }

        this.chargebackOperator = new ChargebackOperator({
            modelConstructor: Chargeback,
            model: newChargeback,
            modules: null,
            data: null,
            validateOptions: { skipMissingProperties: true },
            onSave: async (editedModel) => {
                try {
                    await this.rpcClient.payment.chargebackOrder(this.m._id, editedModel.amount);
                } catch (e: any) {
                    alert("Unable to complete refund.");
                    globalManagementLogger.error(e);
                }

                await this.reloadPayments();
                this.hideChargebackCreation();

                // await this.calculatePrice();
                // await this.calculateFee();

                this.orderChargebackInProgress = false;
            },
        });

        this.chargebackOperator.edit(() => {});
    }

    @action
    public hideChargebackCreation(): void {
        this.isChargebackCreationFormVisible = false;
        this.chargebackOperator = undefined;
    }

    @action
    public async addChargeback() {
        if (!this.isEdited() && this.chargebackOperator) {
            this.orderChargebackInProgress = true;
            await this.chargebackOperator.save();
        }
    }
    // /

    @action
    public updateNonLeadsCountry() {
        const lead = this.passengers.find((po) => po.m.type === PassengerType.Lead);

        if (isUndefinedOrNull(lead)) {
            return;
        }

        this.passengers.forEach((po) => {
            if (po.m.type !== PassengerType.Lead) {
                po.edit((p) => (p.countryId = lead.m.countryId));
            }
        });
    }

    @observable
    public customerFeedbacks: Array<CustomerFeedbackOperator> = [];

    @action
    public async calculateLocationsTravelData(
        contentLocations: Array<OrderContentLocation>,
        customLocations: Array<OrderCustomLocation>,
    ) {
        if (contentLocations.length === 0 && customLocations.length === 0) {
            return;
        }

        let waypoints: Array<Waypoint> = [];

        if (this.m.routeId && this.route) {
            waypoints = this.route.waypoints;
        }

        const contentLocationPromises = customLocations.map(async (location) => {
            // [ch15642] if position doesn't have id assigned, assign temporary id
            // so it can be later paired with the custom location in the OrderLocations component
            if (!location.position._id) {
                location.position._id = uuid.v4();
            }

            const customLocationSimpleTravelData = await this.rpcClient.content.calculateCustomRouteLocationsTravelData(
                location.position,
                this.m.originLocationId,
                this.m.destinationLocationId,
                waypoints,
            );
            this.locationsTravelData.push(customLocationSimpleTravelData);
        });

        const customLocationPromises = contentLocations.map(async (location) => {
            const routeLocation = this.route?.locations.find((rl) => rl.locationId === location.locationId);

            const locationTravelData = await this.rpcClient.content.calculateRouteLocationTravelData(
                location.locationId,
                routeLocation?.order ?? location.order,
                this.m.originLocationId,
                this.m.destinationLocationId,
                waypoints,
            );

            if (locationTravelData) {
                locationTravelData.locationId = location.locationId;

                this.locationsTravelData.push(locationTravelData);
            }
        });
        const [baseTravelData] = await Promise.all([
            this.rpcClient.content.calculateBaseRouteTravelData(
                this.m.originLocationId,
                this.m.destinationLocationId,
                waypoints,
            ),
            ...contentLocationPromises,
            ...customLocationPromises,
        ]);
        this.baseRouteTravelData = baseTravelData;
    }

    @observable
    public allDriversLoaded: boolean = false;

    @computed
    public get customerSearchOption(): Option | undefined {
        if (this.user) {
            return { value: this.user._id, label: `${this.user.fullName} <${this.user.email}>` };
        }
        return undefined;
    }

    private customerSelectUsers: Array<SimpleUser> = [];

    @action
    public async onCustomersFilterInputChange(searchString?: string) {
        if (searchString && searchString.length > 2) {
            this.customerSelectUsers = await this.rpcClient.user.retrieveSimpleUsers({
                searchString,
                isDriver: false,
                isCompanyDriver: false,
                isDriversCompany: false,
            });

            return {
                options: this.customerSelectUsers.map((customer) => ({
                    value: customer._id,
                    label: `${customer.fullName} <${customer.email}>`,
                })),
            };
        }

        return undefined;
    }

    @action
    public async onCustomerFilterSelect(option: Option): Promise<void> {
        this.data.simpleUsers = uniq([...toJS(this.data.simpleUsers ?? []), ...this.customerSelectUsers]);
        this.edit((o) => (o.userId = option && (option.value as string)));
    }

    @observable
    public originLocationSearchOption?: Option;

    @action
    public async onOriginLocationsFilterSelect(option: Option): Promise<void> {
        if (option?.value != null && !this.data.locations.some((l) => l._id === option.value)) {
            this.data.locations.push(await this.rpcClient.content.retrieveLocation(option.value as string));
        }

        this.originLocationSearchOption = option;
        this.edit((o) => (o.originLocationId = option && (option.value as string)));
    }

    @action
    public async loadLocationOptions(value: string): Promise<{ options: Array<Option> }> {
        if (value && value.length > 2) {
            this.simpleLocations = plainToClass(
                SimpleLocation,
                await this.rpcClient.content.retrieveSimpleLocations({ searchString: value as string }),
            );
        }
        return {
            options: this.simpleLocations.map((location) => ({
                value: location.locationId,
                label: location.name + (location.countryName ? `, ${location.countryName}` : ""),
            })),
        };
    }

    @observable
    public destinationLocationSearchOption?: Option;

    @action
    public async onDestinationLocationsFilterSelect(option: Option): Promise<void> {
        if (option?.value != null && !this.data.locations.some((l) => l._id === option.value)) {
            this.data.locations.push(await this.rpcClient.content.retrieveLocation(option.value as string));
        }

        this.destinationLocationSearchOption = option;
        this.edit((o) => (o.destinationLocationId = option && (option.value as string)));
    }

    @observable
    public simpleLocations: Array<SimpleLocation> = [];

    @action
    public async loadDrivers(driversCompanyId?: string, searchString?: string): Promise<SimpleDriver[]> {
        const options: RetrieveUsersOptions = {};

        if (driversCompanyId) {
            options.isCompanyDriver = true;
            options.driversCompanyIds = [driversCompanyId];
        } else {
            options.isDriver = true;
            options.isDriversCompany = true;
            options.isCompanyDriver = false;
        }

        options.isActive = true;

        if (searchString) {
            options.searchString = searchString;
        }

        const simpleDrivers = await this.rpcClient.driver.retrieveSimpleDrivers(options);
        this.data.simpleDrivers = simpleDrivers;

        this.vehicleOperators.forEach((vo) => {
            vo.data.simpleDrivers = this.data.simpleDrivers;
        });

        this.allDriversLoaded = true;

        return simpleDrivers;
    }

    @observable
    public isPickupAddressApprovementLoading = false;

    @observable
    public isDropoffAddressApprovementLoading = false;

    @action
    public async editPickupAddressApprovedAndSave(value: boolean): Promise<void> {
        this.isPickupAddressApprovementLoading = true;

        await this.rpcClient.order.updatePickupAddressApprovement(this.m._id, this.m, value);
        await this.fetchOrder();

        this.isPickupAddressApprovementLoading = false;
        await this.setLocationArrivalTimes();
    }

    @action
    public async editDropoffAddressApprovedAndSave(value: boolean): Promise<void> {
        this.isDropoffAddressApprovementLoading = true;

        await this.rpcClient.order.updateDropoffAddressApprovement(this.m._id, this.m, value);
        await this.fetchOrder();

        this.isDropoffAddressApprovementLoading = false;
        await this.setLocationArrivalTimes();
    }

    @action
    public async loadCreatedBy(): Promise<void> {
        if (this.m?.createdBy) {
            [this.createdByUser] = await this.rpcClient.user.retrieveSimpleUsers({ userIds: [this.m.createdBy] });
        }
    }

    @observable
    public meetingPositions?: Array<MeetingPosition>;

    @computed
    public get meetingPosition(): MeetingPosition | undefined {
        if (this.meetingPositions && this.m.meetingPositionId) {
            return this.meetingPositions.find((mp) => mp._id === this.m.meetingPositionId);
        }
        return undefined;
    }

    public async fetchMeetingPositions(): Promise<void> {
        this.meetingPositions = await this.rpcClient.content.retrieveMeetingPositions({
            locationIds: [this.m.originLocationId],
        });
    }

    @observable
    public orderChangesLog?: OrderChange;

    @observable
    public changedByUsers?: Array<SimpleUser>;

    @observable
    public noChangesFound?: boolean;

    @action
    public async loadOrderChanges(): Promise<void> {
        this.orderChangesLog = plainToClass(
            OrderChange,
            await this.rpcClient.orderChangeLog.retrieveOrderChange(this.m._id),
        );

        if (this.orderChangesLog !== undefined) {
            await this.loadChangedByUsers(
                this.orderChangesLog.logs
                    .filter((l) => l.changedByUserId)
                    .map((l) => l.changedByUserId) as Array<string>,
            );
        } else {
            this.noChangesFound = true;
        }
    }

    @action
    public async loadChangedByUsers(userIds: Array<string>): Promise<void> {
        this.changedByUsers = await this.rpcClient.user.retrieveSimpleUsers({ userIds });
    }

    @observable
    public latestChange?: OrderChangesLog;

    @observable
    public changeId?: string;

    @observable
    public isChangeUpdating: boolean = false;

    @action
    public async retrieveLatestPendingChange() {
        this.latestChange = undefined;
        this.changeId = undefined;

        if (this.m.status !== OrderStatus.Confirmed) {
            return;
        }

        const orderChanges = await this.rpcClient.orderChangeLog.retrieveOrderChange(this.m._id);

        if (!orderChanges) {
            return;
        }

        this.changeId = orderChanges._id;
        const pendingLogs = getPendingLogsFromOrderLog(orderChanges as OrderChange);
        if (pendingLogs.length > 0) {
            [this.latestChange] = pendingLogs;

            // Here we fetch simple locations and simple countries
            // that might have been removed (so not fetched with order
            // data), but we still need to show them in change list
            const newLocationIds: Array<string> = [];
            let newCountryIds: Array<string> = [];

            this.data.simpleLocations = this.data.simpleLocations || [];
            this.data.simpleCountries = this.data.simpleCountries || [];

            this.latestChange.changes.forEach((log) => {
                // eslint-disable-next-line default-case
                switch (log.key) {
                    case "originLocationId":
                    case "destinationLocationId":
                        if (log.previousValue) {
                            newLocationIds.push(log.previousValue as string);
                        }

                        if (log.currentValue) {
                            newLocationIds.push(log.currentValue as string);
                        }
                        break;

                    case "contentLocations": {
                        const uniqueChangeLocationsIds = uniq([
                            ...((log.previousValue || []) as Array<OrderContentLocation>).map((ocl) => ocl.locationId),
                            ...((log.currentValue || []) as Array<OrderContentLocation>).map((ocl) => ocl.locationId),
                        ]);

                        newLocationIds.push(
                            ...difference(
                                uniqueChangeLocationsIds,
                                this.data.simpleLocations!.map((sl) => sl.locationId),
                            ),
                        );
                        break;
                    }

                    case "passengers": {
                        const prevCountryIds: Array<string> = ((log.previousValue || []) as Array<Passenger>)
                            .map((p) => p.countryId)
                            .filter(Boolean);

                        const currentCountryIds: Array<string> = ((log.currentValue || []) as Array<Passenger>)
                            .map((p) => p.countryId)
                            .filter(Boolean);

                        const uniqueChangeCountryIds = uniq([...prevCountryIds, ...currentCountryIds]);

                        newCountryIds = difference(
                            uniqueChangeCountryIds,
                            this.data.simpleCountries!.map((sc) => sc._id),
                        );
                        break;
                    }
                }
            });

            let missingSimpleLocations: Array<SimpleLocation> = [];

            if (newLocationIds.length > 0) {
                missingSimpleLocations = await this.rpcClient.content.retrieveSimpleLocations({ ids: newLocationIds });
                this.data.simpleLocations.push(...missingSimpleLocations);
            }

            if (newCountryIds.length > 0) {
                const newSimpleCountries = await this.rpcClient.content.retrieveSimpleCountries({
                    ids: [...newCountryIds, ...missingSimpleLocations.map((sl) => sl.countryId)],
                });

                this.data.simpleCountries.push(...newSimpleCountries);
            }
        }
    }

    @action
    public async discardLatestChange() {
        if (!this.changeId || !this.latestChange) {
            return;
        }

        this.isChangeUpdating = true;
        await this.rpcClient.orderChangeLog.discardChange(this.changeId, this.latestChange);
        await this.retrieveLatestPendingChange();
        this.isChangeUpdating = false;
    }

    @action
    public async sendTripChangeToCustomer() {
        if (!this.changeId || !this.latestChange) {
            return;
        }

        this.isChangeUpdating = true;

        await this.rpcClient.email.sendEmail<OrderChangesEmailArgs>(EmailTypes.orderChanges, {
            changeId: this.changeId,
            sendingChange: this.latestChange,
        });

        await this.retrieveLatestPendingChange();
        await this.fetchOrder();
        this.isChangeUpdating = false;
    }

    @action
    public async revealCCAlias(): Promise<void> {
        this.ccAliasNotFound = false;
        const payments = this.paymentOperators.map((po) => po.m);
        const successfulCustomerPayments = payments.filter((p: Payment) => p.status === PaymentStatus.Fulfilled);
        if (successfulCustomerPayments[0]) {
            if (successfulCustomerPayments[0].cardAlias) {
                this.ccAlias = successfulCustomerPayments[0].cardAlias;
                return;
            }

            if (successfulCustomerPayments[0].type === PaymentType.Mangopay) {
                this.ccAlias = await this.rpcClient.mangopay.retrieveFullCCAliasFromPayment(
                    successfulCustomerPayments[0],
                );
            } else {
                this.ccAliasNotFound = true;
            }
        }
    }

    @computed
    public get hasInvoicedAssignations(): boolean {
        return this.assignationOperators.reduce((__hasInvoicedAssignations, ao) => !!ao.m.invoiceId, false);
    }

    @computed
    public get hasAssignationClaimUpdatePermission() {
        return this.authenticationStore.hasPermissions("AssignationClaim:Update");
    }

    @computed
    public get hasSedanLite() {
        return this.vehicleOperators.some((vehicle) => vehicle.isSedanLite);
    }

    @observable
    public isPRPaymentProviderVisible: boolean = false;

    @action
    public setPRPaymentProviderVisible() {
        this.isPRPaymentProviderVisible = !this.isPRPaymentProviderVisible;
    }

    @observable
    public apiPartner?: ApiPartnerModel;
}
