import { buildLegacyRpcSdk } from "@daytrip/legacy-rpc-sdk-browser";

import { LEGACY_RPC_URL, REVISION, VERSION } from "./config.management";
import { getAuthenticationStore } from "./domain/authentication/AuthenticationStore.singleton";
import { getAuthenticationTokensOrFail } from "./domain/authentication/utils/getAuthenticationTokensOrFail";
import {
    setManagementAuthenticationToken,
    setManagementRefreshToken,
} from "./utils/authentication/authenticationUtils";
import { ExportedRpcControllersType } from "@daytrip/api";

export type RpcClient = ReturnType<typeof buildLegacyRpcSdk> & ExportedRpcControllersType;
let rpcClient: RpcClient | null = null;

/**
 * Initializes the RPC client with the necessary configuration.
 */
function initRpcClient() {
    return buildLegacyRpcSdk({
        rpcEndpoint: LEGACY_RPC_URL,
        clientName: "management",
        clientRevision: REVISION,
        clientVersion: VERSION,
        tokensRefreshedCallback(tokenPair, bothTokens?) {
            setManagementAuthenticationToken(tokenPair.authentication);
            if (bothTokens) {
                setManagementRefreshToken(tokenPair.refresh);
            }

            getAuthenticationStore().setAuthenticationToken(tokenPair.authentication);
        },
        refreshTokenExpiredCallback() {
            getAuthenticationStore().logout(true);
        },
        tokensProvider() {
            return getAuthenticationTokensOrFail();
        },
    });
}

/**
 * pretty dumb way of implementing a "singleton"
 */
export function getRpcClient() {
    if (!rpcClient) {
        rpcClient = initRpcClient() as RpcClient;
    }

    return rpcClient;
}
